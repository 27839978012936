import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from '../../services/app.service/app.service';
import {DialogService} from '../../components/dialog-message/dialog-message.service';
import {UserService} from '../../services/user.service/user.service';
import {CounselService} from '../../services/counsel.service/counsel.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-studentmanage-detail',
  templateUrl: './studentmanage-detail.component.html',
  styleUrls: ['./studentmanage-detail.component.scss']
})
export class StudentmanageDetail implements OnInit {
  subscriber: any;

  public options = {
    rows: [],
    selected: null,
    headerHeight: '56px',
    footerHeight: '80px',
    rowHeight: '80px',
    bodyHeight: '400px',
    limit: 5,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  //for Model
  studentId: any;
  student: any;

  counsels: any = [];
  counselContent: string;

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              public userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialogService: DialogService,
              private counselService: CounselService,
              private location: Location) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {

    this.subscriber = this.activatedRoute.params
      .subscribe(params => {
        this.studentId = params['student'];

        this.loadStudent();
        this.loadCounsels({page: this.options.page});
      });
  }

  /*****************************
   *        util functions
   *****************************/

  toggleCounselButton(counsel) {
    counsel.checkModify = !counsel.checkModify;
  }

  gotoList() {
    this.location.back();
  }

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  /*****************************
   *       helper functions
   *****************************/

  loadStudent(): any {
    this.userService.findOne({
      query: {
        _id: this.studentId
      },
      populate: ['class', 'campus'],
    }).pipe(
      delay(300)
    ).subscribe((result) => {
      this.student = result.user;
      this.finishLoad = true;
    }, error => {
      this.finishLoad = true;
      this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.\n');;
    });
  }

  loadCounsels(event) {
    this.options.page = event.page;

    let params = {
      query: {
        student: this.studentId,
        isDeleted: false
      },
      limit: this.options.limit,
      skip: (this.options.page - 1) * this.options.limit,
      sort: {_id: -1}
    };

    this.counselService.find(params)
      .subscribe(
        (data) => {
          this.options.rows = data.counsels;
          this.options.count = data.total;
          _.forEach(this.counsels, (counsel) => {
            counsel.checkModify = false;
          });
        },
        () => {
          this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.');
        },
      );
  }

  applyCounsel() {
    if (this.counselContent && this.counselContent != '') {
      let counsel = {
        content: this.counselContent,
        student: this.studentId
      };

      this.counselService.create(counsel)
        .subscribe((result) => {
          this.counselContent = '';
          this.loadCounsels({page: this.options.page});
          this.dialogService.message("알림", "상담일지가 등록되었습니다")
        }, error => {
          console.log('error :::\n', error);
          this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다\n');;
        });
    } else {
      this.dialogService.message('알림', '상담일지 내용을 작성해주세요')
        .subscribe(() => {
        });
    }
  }

  modifyCounsel(counsel) {
    if (counsel.content && counsel.content != '') {
      delete counsel.checkModify;
      this.counselService.update(counsel)
        .subscribe((result) => {
          this.dialogService.message("알림", "상담일지가 수정되었습니다\n")
            .subscribe(() => {
              this.loadCounsels({page: this.options.page});
            });
        }, error => {
          console.log('error :::\n', error);
          this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.\n');
        });
    }
  }

  removeCounsel(counsel) {
    this.dialogService.confirm('알림', '해당 상담일지를 삭제하시겠습니까?')
      .subscribe((result) => {
        if (result) {
          this.counselService.remove(counsel._id)
            .subscribe((result) => {
              this.dialogService.message("알림", "상담일지가 삭제되었습니다\n")
                .subscribe(() => {
                  this.loadCounsels({page: this.options.page});
                });
            }, error => {
              console.log('error :::\n', error);
              this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.\n');
            });
        }
      });
  }
}
