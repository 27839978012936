import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private serverUrl = environment.serverUrl + '/user';

  gradeQueryParams: any = null;
  gradePage: number = 1;
  gradeClassId: number = null;

  studentQueryParams: any = null;
  studentPage: number = 1;
  studentClassId: number = null;

  constructor(private httpClient: HttpClient) {}

  count(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/count';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  update(params: Object): Observable<any> {
    return this.httpClient
      .put(this.serverUrl, params);
  }

  findTopTen(params: any): Observable<any> {
    return this.httpClient
      .post(this.serverUrl + '/findTopTen', params);
  }

  findClassWinner(params: any): Observable<any> {
    return this.httpClient
      .post(this.serverUrl + '/findClassWinner', params);
  }

  stat(id: any) {
    let url = this.serverUrl + '/stat';

    const queryParams = {
      id: id
    };

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  classUpdate(params: Object): Observable<any> {
    return this.httpClient
      .put(this.serverUrl + '/user/classUpdate', params);
  }

  getGradeQueryParams() {
    return this.gradeQueryParams;
  }

  setGradeQueryParams(params) {
    this.gradeQueryParams = params;
  }

  getGradePage() {
    return this.gradePage;
  }

  setGradePage(page) {
    this.gradePage = page;
  }

  getGradeClassId() {
    return this.gradeClassId;
  }

  setGradeClassId(id) {
    this.gradeClassId = id;
  }

  initGradeParams() {
    this.gradeQueryParams = null;
    this.gradePage = 1;
    this.gradeClassId = null;
  }

  getStudentQueryParams() {
    return this.studentQueryParams;
  }

  setStudentQueryParams(params) {
    this.studentQueryParams = params;
  }

  getStudentPage() {
    return this.studentPage;
  }

  setStudentPage(page) {
    this.studentPage = page;
  }

  getStudentClassId() {
    return this.studentClassId;
  }

  setStudentClassId(id) {
    this.studentClassId = id;
  }

  initStudentParams() {
    this.studentQueryParams = null;
    this.studentPage = 1;
    this.studentClassId = null;
  }
}
