import * as _ from 'lodash';
import {Component, OnInit, ViewChild, ElementRef, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {ClassService} from "../../services/class.service/class.service";
import {AppService} from "../../services/app.service/app.service";

@Component({
  selector: 'app-dialog-class-manage',
  templateUrl: './dialog-class-manage.component.html',
  styleUrls: ['./dialog-class-manage.component.scss']
})
export class DialogClassManage implements OnInit {
  public classObj: any;

  constructor(public dialogRef: MatDialogRef<any>,
              public classService: ClassService,
              private appService: AppService,
              @Inject(MAT_DIALOG_DATA) public data:any) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.classObj = {};
    if(!!this.data.class){ // update class
      this.classObj._id = this.data.class._id;
      this.classObj.className = this.data.class.className;
      this.classObj.totalStudents = this.data.class.totalStudents;
      this.classObj.classInfo = this.data.class.classInfo;
      this.classObj.campus = this.data.class.campus;
    }else{ // create class
      this.classObj = {
        className : "",
        totalStudents : "",
        classInfo : "",
        campus: this.appService.user.campus._id
      }
    }

  }

  /*****************************
   *        util functions
   *****************************/

  save() {
    if(!!this.classObj._id){ // update class
      console.log("update");
      this.classService
        .update(this.classObj)
        .subscribe(
          data =>{
            console.log(data);
            this.dialogRef.close();
          }
        )
    }else{ // create class
      console.log("create");
      this.classService
        .create(this.classObj)
        .subscribe(
          data=>{
            console.log(data);
            this.dialogRef.close();
          }
        )
    }
  }

  close() {
    this.dialogRef.close();
  }
}
