import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from "rxjs";
import { TimerService } from '../../services/timer.service/timer.service';

@Component({
  selector: 'timer',
  template: ` 
        <span>
            {{(minutesDisplay) && (minutesDisplay <= 59) ? minutesDisplay : '00'}} : {{(secondsDisplay) && (secondsDisplay <= 59) ? secondsDisplay : '00'}} <br/>
        </span>
    `
})
export class Timer implements OnInit, OnDestroy {

  private playPauseStopUnsubscribe: any;

  ticks = 0;

  minutesDisplay: number = 0;
  secondsDisplay: number = 0;

  sub: Subscription;

  // start variable to tell the timer when to start
  start = 0;

  // inject the timer service
  constructor(private timerService: TimerService) {
  }
  ngOnInit() {
    // subscribe to the playPauseStop$ EventEmitter in the timer service
    this.playPauseStopUnsubscribe = this.timerService.playPauseStop$.subscribe((res: any) => this.playPauseStop(res));
  }
  ngOnDestroy() {
    // unsubscribe from the EventEmitter when the component is destroyed
    this.playPauseStopUnsubscribe.unsubscribe();
  }

  private playPauseStop(res: any) {
    // check the object passed by the EventEmitter
    // and call relevant method based on the action
    if(res.play) {
      this.startTimer();
    } else if(res.pause) {
      this.pauseTimer();
    } else if (res.stop) {
      this.stopTimer();
    }
  }

  private startTimer() {
    this.sub = timer(1, 1000).subscribe(
      t => {
        // use the start variable to begin timer
        this.ticks = this.start + t;
        this.secondsDisplay = this.getSeconds(this.ticks);
        this.minutesDisplay = this.getMinutes(this.ticks);
      }
    );
  }

  // set the start variable and unsubscribe from the timer observable
  // will be used to start timer
  private pauseTimer() {
    this.start = ++this.ticks;
    if (this.sub) this.sub.unsubscribe();
  }
  //re-set all variables and unsubscribe from the timer observable
  private stopTimer() {
    this.start = 0;
    this.ticks = 0;
    this.minutesDisplay = 0;
    this.secondsDisplay = 0;
    if (this.sub) this.sub.unsubscribe();
  }

  private getSeconds(ticks: number) {
    return this.pad(ticks % 60);
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
}
