import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {UserService} from "../../services/user.service/user.service";
import {ClassService} from "../../services/class.service/class.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {RouteService} from '../../services/route.service/route.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-studentmanage-list',
  templateUrl: './studentmanage-list.component.html',
  styleUrls: ['./studentmanage-list.component.scss']
})
export class StudentmanageList implements OnInit {
  selectedQuery: any;
  searchQueries: any;

  queryParams: any;
  queryString: string;
  queryWrapper: any;

  public options = {
    rows: [],
    selected: null,
    headerHeight: "56px",
    footerHeight: "80px",
    rowHeight: "80px",
    bodyHeight: "100%",
    limit: 10,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  classes: any;
  currentClassId: number;
  studentsCount: number;

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              public userService: UserService,
              public classService: ClassService,
              private dialogService: DialogService,
              private router: Router,
              private routeService: RouteService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.selectedQuery = "이름";
    this.searchQueries = ['이름'];

    this.queryString = '';
    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      isDeleted: false,
      approvalStatus: true,
      role: "학생"
    };

    this.loadCount();
    this.loadClass();
    this.loadList({page: this.options.page});
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  onEvent(event) {
    event.stopPropagation();
  }

  goDetail(student) {
    if(this.appService.user.role == '관리자')
      this.router.navigate(['/admin/my-campus/student-manage/studentmanage-detail', student._id]);
    else if(this.appService.user.role == '원장님' || this.appService.user.role == '정회원 선생님')
      this.router.navigate(['/regular-academy/my-campus/student-manage/studentmanage-detail', student._id]);
    else if(this.appService.user.role == '준회원 원장님' || this.appService.user.role == '준회원 선생님')
      this.router.navigate(['/associate-academy/my-campus/student-manage/studentmanage-detail', student._id]);
  }

  changeClassCategory(classId?) {
    this.options.page = 1;

    this.queryString = '';
    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      isDeleted: false,
      approvalStatus: true,
      role: '학생'
    };

    if (classId) {
      this.currentClassId = classId;
      this.queryWrapper.class = classId;
    } else {
      this.currentClassId = null;
    }

    this.loadList({page: this.options.page});
  }

  checkUrl () {
    // TODO: -manage 갔다가 list 갈 경우 잘못된 쿼리를 날림
    const splitedString = _.split(this.routeService.getPreviousUrl(), '/', 4);
    const manageType = _.split(splitedString[3], '-', 1);

    return (manageType[0] === 'student') && this.routeService.getPreviousUrl().includes('-detail')
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCount() {
    let params = {
      query: {
        campus: this.appService.user.campus._id,
        isDeleted: false,
        approvalStatus: true,
        role: '학생'
      }
    }

    this.userService.count(params)
      .subscribe(
        (data) => {
          this.studentsCount = data.count;
        },
        (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다");
        });
  }

  loadClass() {
    let params = {
      query: {
        campus: this.appService.user.campus._id
      },
      populate: [
        {
          path: "students",
          match: {
            isDeleted: false
          },
        },
      ]
    };

    this.classService.find(params)
      .subscribe((data) => {
          this.classes = data.classes;
        }, (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다")
        }
      )
  }

  loadList(event) {
    this.finishLoad = false;
    this.options.rows = [];
    this.appService.sendEvent('scrollTop');
    this.options.page = event.page;

    if(this.checkUrl()) {
      this.queryParams = this.userService.getStudentQueryParams();
      this.options.page = this.userService.getStudentPage();
      this.currentClassId = this.userService.getStudentClassId();
      this.userService.initStudentParams();
      this.routeService.previousUrl = "";
    } else {
      this.queryParams = {
        query: this.queryWrapper,
        populate: ['class'],
        limit: this.options.limit,
        skip: (this.options.page - 1) * this.options.limit,
        sort: {_id: -1}
      }
    }

    this.userService.setStudentQueryParams(this.queryParams);
    this.userService.setStudentPage(this.options.page);
    this.userService.setStudentClassId(this.currentClassId);

    this.userService.find(this.queryParams)
      .pipe(
        delay(300)
      ).subscribe(
        (data) => {
          this.options.rows = data.users;
          this.options.count = data.total;

          this.finishLoad = true;
        },
        (error) => {
          console.log("error :::\n", error);
          this.finishLoad = true;
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다");
        });
  }

  checkClass(getClass, userClass) {
    if(userClass) {
      return getClass._id === userClass._id;
    } else {
      return false;
    }
  }

  classChange(user, input) {
    user['class'] = input.target.value;

    this.userService.update(user)
      .subscribe((data) => {
        this.loadClass();
        this.loadList({page: this.options.page});
      }, (error) => {
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다")
      });
  }

  searchByQuery() {
    if (this.queryString && this.queryString != '') {
      this.queryWrapper.name = {$regex: ".*" + this.queryString + ".*"};
      this.loadList({page: this.options.page});
    } else {
      this.dialogService.message("알림", "학생의 이름을 입력해 주세요");
    }
  }
}
