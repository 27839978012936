import * as _ from 'lodash';
import {Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {PostService} from "../../services/post.service/post.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {AppService} from "../../services/app.service/app.service";
import {ClassService} from "../../services/class.service/class.service";
import {CampusService} from "../../services/campus.service/campus.service";
import {delay} from 'rxjs/operators';
import {RouteService} from '../../services/route.service/route.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class Post implements OnInit {
  selectedQueryType: any;
  queryTypes: any;
  queryString: string;

  videoCategories: any = [];
  selectedCategory: string = '';

  queryParams: any;
  queryWrapper: any;
  public options = {
    rows: [],
    selected: null,
    headerHeight: "56px",
    footerHeight: "80px",
    rowHeight: "80px",
    bodyHeight: "100%",
    limit: 10,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  classes: any;
  currentClassId: number;

  currentPost: any;

  title: any;
  subTitle: any;
  urlArray: any;

  userRole: any;
  writeCheck: boolean = false;

  imageUrl: string = '';

  toggleComment: boolean = true;

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              private postService: PostService,
              public classService: ClassService,
              private campusService: CampusService,
              private dialogService: DialogService,
              public router: Router,
              private routeService: RouteService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.selectedQueryType = "제목";
    this.queryTypes = ['제목', '제목+내용', '작성자'];
    this.queryString = "";

    this.urlArray = this.router.url.split('/');
    let lastUrl = _.last(this.urlArray);
    let lastUrls = lastUrl.split('-');
    this.currentPost = lastUrls[0];

    this.userRole = this.appService.user.role;

    this.queryWrapper = {
      category: this.currentPost
    };

    this.checkPostOptions();
    this.loadList({page: this.options.page});
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  changeClassCategory(classId?) {
    this.options.page = 1;

    if (classId) {
      this.currentClassId = classId;
      this.queryWrapper.class = classId;
    } else {
      this.currentClassId = null;
      delete this.queryWrapper.class;
    }

    this.loadList({page: this.options.page});
  }

  varifyCategory(category?) {
    this.options.page = 1;

    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      category: 'video',
      videoCategory: category
    };

    if (category) {
      this.selectedCategory = category;
    } else {
      this.selectedCategory = '';
    }

    this.loadList({page: this.options.page});
  }

  gotoPostDetail(post, modelType) {
    let path = "";
    for (let i = 0; i < this.urlArray.length - 1; i++) {
      path += this.urlArray[i] + '/'
    }

    this.router.navigate([path + this.currentPost + '-detail', this.currentPost, modelType, post.id]);
  }

  gotoManage(modelType) {
    let path = "";
    for (let i = 0; i < this.urlArray.length - 1; i++) {
      path += this.urlArray[i] + '/'
    }

    let manageType = 'CREATE';
    this.router.navigate([path + this.currentPost + '-manage', this.currentPost, manageType, modelType, ""]);
  }

  checkPostOptions() {
    switch (this.currentPost) {
      case 'notice' : {
        this.title = '공지사항';
        this.subTitle = '우리 캠퍼스만의 다양한 소식과 영어학습을 위한 공지사항';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.queryWrapper.campus = this.appService.user.campus._id;
        break;
      }
      case 'homework' : {
        this.title = '숙제 게시판';
        this.subTitle = '우리 캠퍼스만의 영어학습을 위한 숙제 게시판입니다.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.imageUrl = 'url(assets/img/img_homework.png)';
        this.queryWrapper.campus = this.appService.user.campus._id;
        this.loadClass();
        break;
      }
      case 'essay' : {
        this.title = 'ESSAY 및 영작 게시판';
        this.subTitle = '이 곳은 에세이 숙제와 자유롭게 영작을 하는 공간입니다.';
        if (this.userRole == "학생") this.writeCheck = true;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.imageUrl = 'url(assets/img/img_essay.png)';
        this.queryWrapper.campus = this.appService.user.campus._id;
        this.loadClass();
        break;
      }
      case 'ourstory' : {
        this.title = '우리들의 이야기';
        this.subTitle = '친구들과 만들어가는 우리들만의 이야기';
        if (this.userRole == "학생") this.writeCheck = true;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.imageUrl = 'url(assets/img/img_talk.png)';
        this.queryWrapper.campus = this.appService.user.campus._id;
        break;
      }
      case 'video' : {
        this.title = '동영상 갤러리';
        this.subTitle = '우리 캠퍼스만의 동영상 앨범';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.queryWrapper.campus = this.appService.user.campus._id;
        this.loadCampus();
        break;
      }
      case 'weladatacenter' : {
        this.title = '웰라 자료실';
        this.subTitle = '웰라잉글리쉬 본사에서 제공하는 다양한 영어학습 자료들';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = false;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = false;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        this.toggleComment = false;
        break;
      }
      case 'campusdatacenter' : {
        this.title = '캠퍼스 자료실';
        this.subTitle = '영어학습에 필요한 자료를 가득 담아놓은 캠퍼스만의 학습자료실';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = true;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = true;

        this.queryWrapper.campus = this.appService.user.campus._id;
        break;
      }
      case 'welanews' : {
        this.title = '웰라 소식';
        this.subTitle = '앞으로 더욱 더 발전하는 웰라잉글리쉬클래스가 되겠습니다.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = false;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = false;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        break;
      }
      case 'sarangbang' : {
        this.title = '사랑방';
        this.subTitle = '웰라는 원장님의 소중한 의견을 기다립니다.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        break;
      }
      case 'elementrydatacenter' : {
        this.title = '초등 수업 자료실';
        this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        break;
      }
      case 'elementrygamecenter' : {
        this.title = '초등 게임 자료실';
        this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        break;
      }
      case 'middlecenter' : {
        this.title = '중등 자료실';
        this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
        if (this.userRole == "학생") this.writeCheck = false;
        else if (this.userRole == "정회원 선생님") this.writeCheck = true;
        else if (this.userRole == "준회원 선생님") this.writeCheck = false;
        else if (this.userRole == "원장님") this.writeCheck = true;
        else if (this.userRole == "준회원 원장님") this.writeCheck = false;

        break;
      }
    }
  }

  searchByQuery() {
    if (this.queryString && this.queryString != '') {
      switch (this.selectedQueryType) {
        case '제목' : {
          this.queryWrapper.title = {$regex: ".*" + this.queryString + ".*"};
          this.loadList({page: this.options.page});
          break;
        }
        case '제목+내용' : {
          let otherQuery =
            {
              "$or": [
                {"title": {$regex: ".*" + this.queryString + ".*"}},
                {"contentHtml": {$regex: ".*" + this.queryString + ".*"}}
              ]
            };

          _.merge(this.queryWrapper, otherQuery);
          this.loadList({page: this.options.page});
          break;
        }
        case'작성자': {
          this.queryWrapper.name = this.queryString;
          this.loadList({page: this.options.page});
          break;
        }
      }
    } else {
      this.dialogService.message("알림", "검색어를 입력해주세요")
        .subscribe(() => {
        });
    }
  }

  checkUrl () {
    const splitedString = _.split(this.routeService.getPreviousUrl(), '/', 4);

    return (splitedString[3] === this.currentPost) &&
      (this.routeService.getPreviousUrl().includes('-detail') || this.routeService.getPreviousUrl().includes('-manage'))
  }

  converToInt(data) {
    return ((data + 1) * 80) + "px";
  }

  /*****************************
   *       helper functions
   *****************************/

  loadClass() {
    let params = {
      query: {
        campus: this.appService.user.campus._id
      },
      populate: [
        {
          path: "students",
          match: {
            isDeleted: false
          },
        },
      ]
    };

    this.classService.find(params)
      .subscribe((data) => {
          this.classes = data.classes;
        }, (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다")
        }
      )
  }

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      }
    })
      .subscribe((result) => {
        this.videoCategories = result.campus.videoCategories;
      }, error => {
        console.log("error :::\n", error);
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.");
      });
  }

  loadList(event) {
    this.finishLoad = false;
    this.options.rows = [];
    this.appService.sendEvent('scrollTop');
    this.options.page = event.page;

    if(this.checkUrl()) {
      this.queryParams = this.postService.getPostQueryParams();
      this.options.page = this.postService.getPostPage();
      this.currentClassId = this.postService.getPostClassId();
      this.selectedCategory = this.postService.getVideoCategory();
      if(this.postService.getVideoCategory() !== "")
        this.queryWrapper.videoCategory = this.postService.getVideoCategory();

      this.postService.initPostParams();
      this.routeService.previousUrl = "";
    } else {

      this.queryParams = {
        query: this.queryWrapper,
        populate: [
          'owner',
          {
            path: 'replies',
            match: {isDeleted: false},
            populate: [
              {path: 'owner'},
              {path: 'comments', match: {isDeleted: false}}
            ]
          },
          {
            path: 'comments',
            match: {isDeleted: false}, populate: {path: 'owner'}
          }
        ],
        limit: this.options.limit,
        skip: (this.options.page - 1) * this.options.limit,
        sort: {_id: -1}
      };
    }

    this.postService.setPostQueryParams(this.queryParams);
    this.postService.setPostPage(this.options.page);
    this.postService.setPostClassId(this.currentClassId);
    this.postService.setVideoCategory(this.selectedCategory);

    this.postService.find(this.queryParams)
      .pipe(
        delay(300)
      ).subscribe(
        (data) => {
          this.options.rows = data.posts;
          this.options.count = data.total;

          this.finishLoad = true;
        },
        (error) => {
          console.log("error::\n", error);
          this.finishLoad = true;
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.");
        },
      );
  }
}
