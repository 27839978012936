import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {AppService} from '../app.service/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private serverUrl = environment.serverUrl;

  constructor(private appService: AppService,
              private httpClient: HttpClient) {}

  getMyUserInfo(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/me';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  updateMyInfo(user): Observable<any> {
    let url = this.serverUrl + '/me';

    return this.httpClient
      .put(url, user);
  }

  register(params:Object): Observable<any> {
    let url = this.serverUrl + '/register';

    return this.httpClient
      .post(url, params);
  }

  login(identifier: string, password: string): Observable<any> {
    let url = this.serverUrl + '/login';

    return this.httpClient
      .post(url, {identifier: identifier, password: password});
  }

  isAuthenticated(): boolean {
    const user = this.appService.user;
    const token = this.appService.token;

    return user && token;
  }

  logout(): Observable<any> {
    let url = this.serverUrl + '/logout';

    return this.httpClient
      .get(url);
  }

  support(enquiry): Observable<any> {
    const formData = new FormData();
    formData.append("name", enquiry.name);
    formData.append("email", enquiry.email);
    formData.append("content", enquiry.content);
    formData.append("phone", enquiry.phone);
    formData.append("mobile", enquiry.mobile);
    formData.append("category", enquiry.category);

    return this.httpClient
      .post(this.serverUrl + '/support', formData);
  }

  forgotPasswordStart(identifier: string, email: string): Observable<any> {
    let url = this.serverUrl + '/forgotPassword';

    return this.httpClient
      .post(url, {identifier: identifier, email: email});
  }

  forgotPasswordCheck(identifier: string, email: string, code: string): Observable<any> {
    let url = this.serverUrl + '/forgotPasswordCheck';

    return this.httpClient
      .post(url, {identifier: identifier, email: email, code: code});
  }

  forgotPasswordComplete(newPassword: string): Observable<any> {
    let url = this.serverUrl + '/forgotPasswordComplete';

    return this.httpClient
      .put(url, {newPassword: newPassword});
  }
}
