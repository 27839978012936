import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {CampusService} from "../../services/campus.service/campus.service";
import {DialogService} from '../../components/dialog-message/dialog-message.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class Intro implements OnInit {

  campus = {};

  constructor(public appService: AppService,
              private campusService: CampusService,
              public router: Router,
              private dialogService: DialogService) { }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    if(this.appService.user && this.appService.token) {
      if(this.appService.user.role !== '관리자') {
        this.loadCampus();
      }
    }
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  gotoExternalLink(param) {
    switch(param) {
      case 'wela-times-academy' : {
        window.open("http://timesacademy.kr", "_blank");
        break;
      }
      case 'ne-times' : {
        window.open("http://www.netimes.co.kr", "_blank");
        break;
      }
      case 'campus-link' : {
        window.open(this.campus['linkUrl'], "_blank");
        break;
      }
    }
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      }
    })
      .subscribe((result) => {
        this.campus = result.campus;
      }, error => {
        console.log("error :::\n", error);
        let message:string;

        if (error.status === 401) {
          message = "세션이 종료되었습니다. 다시 로그인 해주시기 바랍니다.";
        } else {
          message = "서버와의 통신 중 에러가 발생하였습니다.";
        }

        this.appService.sendEvent('logout');
        this.dialogService.message("알림", message);
      });
  }

}
