import * as _ from 'lodash';
import {Observable} from "rxjs";
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampusService {
  private serverUrl = environment.serverUrl + '/campus';

  constructor(private httpClient: HttpClient) {}

  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  create(params: Object): Observable<any> {
    return this.httpClient
      .post(this.serverUrl, params);
  }

  update(params: Object): Observable<any> {
    return this.httpClient
      .put(this.serverUrl, params);
  }

  remove(_id: string): Observable<any> {
    let httpParams = new HttpParams().set('_id', _id);
    let options = { params: httpParams };

    return this.httpClient
      .delete(this.serverUrl, options);
  }
}
