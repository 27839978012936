import * as _ from 'lodash';
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {BookService} from "../../services/book.service/book.service";

@Component({
  selector: 'app-dialog-grade-record',
  templateUrl: './dialog-grade-record.component.html',
  styleUrls: ['./dialog-grade-record.component.scss']
})
export class DialogGradeRecord implements OnInit {
  @ViewChild('listeningAudio', {static: false}) listeningAudio: ElementRef;

  selectedDate;
  recordingData;

  // tempFile = 'https://images.applicat.co.kr/Ol6PjGs7vJRg9VLQcZ32dejR.mp3';

  constructor(public dialogRef: MatDialogRef<DialogGradeRecord>,
              private bookService: BookService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    //TODO: seeking audio tag
    // this.initListeningAudio();

  }

  // initListeningAudio() {
  //   setTimeout(() => {
  //     let audio = this.listeningAudio.nativeElement;
  //     audio.onended = () => {
  //       audio.load();
  //     };
  //     audio.onplaying = () => {
  //     }
  //   }, 4000);
  // }

  /*****************************
   *        util functions
   *****************************/

  getRecordingLabel(recording) {
    let bookCategory = '';
    let bookTitle = '';
    let fileName = '';

    if (recording.book && recording.listeningFile) {
      bookCategory = this.bookService.findCategory(recording.book.category).title;
      bookTitle = recording.book.title;
      fileName = recording.listeningFile ? recording.listeningFile.fileName : '';
      return bookCategory + '/' + bookTitle + '/' + fileName;
    } else {
      return '';
    }
  }

  close() {
    this.dialogRef.close();
  }
}
