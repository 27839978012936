import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// 3rd Party Modules
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import {NgxPrintModule} from 'ngx-print';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

//components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {Sidenav} from './components/sidenav/sidenav.component';
import { Banner } from './components/banner/banner.component';
import { DialogMessage } from './components/dialog-message/dialog-message.component';
import { ContentCompiler } from './components/content-compiler/content-compiler';
import { PostcodePopup } from './components/postcode-popup/postcode-popup.component';
import { headerInterceptor } from './lib/headerInterceptor';
import { Pager } from './components/pager/pager.component';
import { Timer } from './components/timer/timer.component';
import { DialogCampus } from './components/dialog-campus/dialog-campus.component';
import { UsagePolicy } from './components/usage-policy/usage-policy.component';
import { PrivatePolicy } from './components/private-policy/private-policy.component';
import { DialogGradeRecord } from './components/dialog-grade-record/dialog-grade-record.component';
import { DialogGradeListening } from './components/dialog-grade-listening/dialog-grade-listening.component';
import { DialogGradeAttend } from './components/dialog-grade-attend/dialog-grade-attend.component';
import { DialogGradeAdditional } from './components/dialog-grade-additional/dialog-grade-additional.component';
import { Footer } from './components/footer/footer.component';

// pipes
import { MainPipe } from './pipes/main-pipe.module';

// pages
import { Intro } from './pages/intro/intro.component';
import { WelaBrand } from './pages/wela-brand/wela-brand.component';
import { BookInfo } from './pages/book-info/book-info.component';
import { PriodicalEducation } from './pages/priodical-education/priodical-education.component';
import { JoinMember } from './pages/join-member/join-member.component';
import { RegisterUser } from './pages/register-user/register-user.component';
import { ListeningReading } from './pages/listening-reading/listening-reading.component';
import { UserFind } from './pages/user-find/user-find.component';
import { Post } from './pages/post/post.component';
import {IntroGreeting} from './pages/intro-greeting/intro-greeting.component';
import {PostDetail} from './pages/post/post-detail/post-detail.component';
import { CommunityWinner } from './pages/community-winner/community-winner.component';
import { PostManage } from './pages/post/post-manage/post-manage.component';
import { Grade } from './pages/grade/grade.component';
import { GradeDetail } from './pages/grade-detail/grade-detail.component';
import { StudentmanageDetail } from './pages/studentmanage-detail/studentmanage-detail.component';
import { StudentmanageList } from './pages/studentmanage-list/studentmanage-list.component';
import { ClassManage } from './pages/class-manage/class-manage.component';
import { DialogClassManage } from './components/dialog-class-manage/dialog-class-manage.component';
import { CommunityPhoto } from './pages/community-photo/community-photo.component';
import { StudentGrade } from './pages/student-grade/student-grade.component';
import { MainUsagePolicy } from './pages/main-usage-policy/main-usage-policy.component';
import { MainPrivatePolicy } from './pages/main-private-policy/main-private-policy.component';

@NgModule({
  declarations: [
    //components
    AppComponent,
    Sidenav,
    Banner,
    DialogMessage,
    DialogGradeRecord,
    DialogGradeListening,
    DialogGradeAttend,
    DialogGradeAdditional,
    DialogClassManage,
    PostcodePopup,
    DialogCampus,
    ContentCompiler,
    UsagePolicy,
    PrivatePolicy,
    Timer,
    Pager,
    Footer,

    //pages
    Intro,
    WelaBrand,
    BookInfo,
    PriodicalEducation,
    JoinMember,
    RegisterUser,
    ListeningReading,
    UserFind,
    Post,
    IntroGreeting,
    PostDetail,
    CommunityWinner,
    PostManage,
    Grade,
    GradeDetail,
    StudentmanageDetail,
    StudentmanageList,
    ClassManage,
    CommunityPhoto,
    StudentGrade,
    MainUsagePolicy,
    MainPrivatePolicy
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,

    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatMenuModule,

    MainPipe,
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'underline', 'strike'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link']
        ]
      }
    }),
    NgxPrintModule,
    FileUploadModule,
    SwiperModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: headerInterceptor,
      multi: true
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  entryComponents: [
    Banner,
    DialogMessage,
    DialogGradeRecord,
    DialogGradeListening,
    DialogGradeAttend,
    DialogGradeAdditional,
    DialogClassManage,
    PostcodePopup,
    DialogCampus,
    PrivatePolicy,
    UsagePolicy
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
