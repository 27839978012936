declare var daum;
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {Router, ActivatedRoute} from "@angular/router";
import {PostcodePopup} from '../../components/postcode-popup/postcode-popup.component'
import {DialogCampus} from '../../components/dialog-campus/dialog-campus.component'
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {AuthService} from '../../services/auth.service/auth.service';
import {AppService} from "../../services/app.service/app.service";
import {UsagePolicy} from "../../components/usage-policy/usage-policy.component";
import {PrivatePolicy} from "../../components/private-policy/private-policy.component";
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUser implements OnInit {
  regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  campusObj: any;

  userObj: any;
  passwordChk: any;
  addressDetail: any;
  mobileArr: any;
  isEditMode: any;
  finishLoad: boolean = false;

  constructor(public matDialog: MatDialog,
              private dialogService: DialogService,
              private router: Router,
              public appService: AppService,
              private activatedRoute: ActivatedRoute,
              public authService: AuthService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.isEditMode = false;

    this.userObj = {};
    this.mobileArr = [];

    this.activatedRoute.queryParams
      .subscribe((params: any) => {
        if (params) {
          this.isEditMode = params.isEditMode;
          if (this.isEditMode) {
            this.loadUser();
          }
        }
      }, (err) => {
        console.log(err);
      })
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user', {'isEditMode': true}]);
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  openUsagePolicy() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;

    let dialogRef = this.matDialog.open(UsagePolicy, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.campusObj = result;
        this.userObj.campus = result._id;
      }
    })
  }

  openPrivatePolicy() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;

    let dialogRef = this.matDialog.open(PrivatePolicy, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.campusObj = result;
        this.userObj.campus = result._id;
      }
    })
  }

  isValid() {
    let valid = true, msg;

    if (!this.campusObj) {
      valid = false;
      msg = "캠퍼스를 검색해 주세요.";
    } else if (!this.userObj.identifier && !this.isEditMode) {
      valid = false;
      msg = "아이디를 입력해주세요.";
    } else if (!this.userObj.password && !this.isEditMode) {
      valid = false;
      msg = "패스워드를 입력해주세요.";
    } else if (!this.passwordChk && !this.isEditMode) {
      valid = false;
      msg = "패스워드를 확인해 주세요.";
    } else if (!this.userObj.email) {
      valid = false;
      msg = "이메일을 입력해주세요.";
    } else if (!this.regExp.test(this.userObj.email)) {
      valid = false;
      msg = "이메일 형식이 맞지 않습니다. 이메일 형식으로 입력해주세요.";
    } else if (!this.userObj.name) {
      valid = false;
      msg = "이름을 입력해주세요.";
    } else if (!this.userObj.mobile) {
      valid = false;
      msg = "휴대폰 번호를 입력해주세요.";
    } else if (!this.userObj.address) {
      valid = false;
      msg = "주소를 입력해주세요.";
    } else if (!this.userObj.policyAgree) {
      valid = false;
      msg = "이용약관 및 개인정보 취급방침 동의 버튼을 눌러주세요.";
    }

    if (valid == false) {
      this.dialogService.message("알림", msg);
    } else
      return true;
  }

  submit() {
    this.userObj.birth = new Date('2017-01-01');
    this.userObj.mobile = this.mobileArr.join('-');

    if (!this.isEditMode) {
      this.register();
    } else {
      this.updateUser();
    }

  }

  openAddressModal() {
    let self = this;
    const width = 500;
    const height = 600;

    new daum.Postcode({
      oncomplete: function(data) {
        const zonecode = data.zonecode;
        const postcode = data.postcode === '' ? '' : (' (' + data.postcode + ')');
        const buildingNanme = data.buildingName === '' ? '' : (' (' + data.buildingName + ')')

        self.userObj.zipCode = zonecode + postcode;
        self.userObj.address = data.roadAddress + buildingNanme;
      }
    }).open({
      left: (window.screen.width / 2) - (width / 2),
      top: (window.screen.height / 2) - (height / 2)
    });
  }

  openCampusModal() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;

    let dialogRef = this.matDialog.open(DialogCampus, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.campusObj = result;
        this.userObj.campus = result._id;
      }
    })
  }

  /*****************************
   *       helper functions
   *****************************/

  loadUser() {
    this.authService.getMyUserInfo({
      query: {},
      populate: ['campus']
    }).pipe(
      delay(300)
    ).subscribe(
        (result) => {
          this.userObj = result.user;
          this.campusObj = this.userObj.campus;
          this.mobileArr = this.userObj.mobile.split('-');
          this.finishLoad = true;
        },
        (err) => {
          console.log("err :::\n", err);
          this.finishLoad = true;
          this.dialogService.message("알림", "서버와의 통신중 에러가 발생하였습니다.");
        });
  }

  register() {
    if (this.isValid()) {
      this.userObj.campus = this.campusObj._id;
      this.authService.register(this.userObj)
        .subscribe(
          data => {
            this.dialogService
              .message("가입완료", "회원가입이 정상적으로 완료되었습니다. 캠퍼스 원장님의 승인 절차가 진행 된 후 웰라 웹사이트를 이용 하실 수 있습니다.")
              .subscribe(() => {
                this.router.navigate(['/'])
              })
          },
          error => {
            switch (error.status) {
              case 409:
                this.dialogService.message("알림", "동일한 아이디가 이미 존재합니다.")
                  .subscribe(() => {
                  });
                break;
              case 400:
                this.dialogService.message("알림", "패스워드는 6자이상으로 작성해 주세요")
                  .subscribe(() => {
                  });
                break;
              default:
                this.dialogService.message("알림", "잘못된 요청입니다.");
                return;
            }
          });
    }
  }

  updateUser() {
    if (this.isValid())
      this.authService.updateMyInfo(this.userObj)
        .subscribe(
          result => {
            result.user.campus = this.appService.user.campus;
            result.user.role = this.appService.user.role;
            this.appService.user = result.user;
            this.appService.sendEvent('updateUser');

            this.dialogService
              .message("수정완료", "회원정보가 정상적으로 수정되었습니다.")
              .subscribe(() => {
                this.router.navigate(['/'])
              })
          },
          error => {
            switch (error.status) {
              case 409:
                this.dialogService.message("알림", "동일한 아이디가 이미 존재합니다.")
                  .subscribe(() => {
                  });
                break;
              case 400:
                this.dialogService.message("알림", "패스워드는 6자이상으로 작성해 주세요")
                  .subscribe(() => {
                  });
                break;
              default:
                this.dialogService.message("알림", "잘못된 요청입니다.");
                return;
            }
          });
  }
}
