import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";

@Component({
  selector: 'app-priodical-education',
  templateUrl: './priodical-education.component.html',
  styleUrls: ['./priodical-education.component.scss']
})
export class PriodicalEducation implements OnInit {
  constructor(public appService: AppService,
              public router: Router) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  /*****************************
   *       helper functions
   *****************************/
}
