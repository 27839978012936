import * as _ from 'lodash';
declare var RecordRTC;
import {finalize, delay} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialogConfig, MatDialog} from "@angular/material";

import {BookService} from "../../services/book.service/book.service";
import {PointService} from "../../services/point.service/point.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {FileService} from "../../services/file.service/file.service";
import {TimerService} from "../../services/timer.service/timer.service";
import {AppService} from "../../services/app.service/app.service";

@Component({
  selector: 'app-listening-reading',
  templateUrl: './listening-reading.component.html',
  styleUrls: ['./listening-reading.component.scss']
})
export class ListeningReading implements OnInit, AfterViewInit {
  @ViewChild('listeningAudio', {static: false}) listeningAudio: ElementRef;
  @ViewChild('recording', {static: false}) recording: ElementRef;

  public actionCtrl: boolean;
  public urlArr: Array<any>;
  public pageDrive: any;
  public currentPage: string;

  //for model
  queryWrapper: any;
  books: any = [];
  selectedBook: any = null;
  selectedTrack: any = null;
  bookCategory: string;

  //for listening
  finishToggle: any = false;

  // for record
  public recordRTC: any;
  public stream: any;

  recordFileUploader: any;
  recordedFile: any;

  applyToggle: boolean = false;
  recordingToggle: boolean = false;

  //for timer
  private playPauseStopUnsubscribe: any;
  private play: boolean;

  finishLoad: boolean = false;

  constructor(public bookService: BookService,
              private pointService: PointService,
              private dialogService: DialogService,
              private fileService: FileService,
              private timerService: TimerService,
              private route: ActivatedRoute,
              public matDialog: MatDialog,
              public router: Router,
              public appService: AppService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.playPauseStopUnsubscribe = this.timerService.playPauseStop$.subscribe((res: any) => this.setPlay(res));

    this.actionCtrl = true;

    this.pageDrive = {
      teacher: false,
      student: false,
      before: false
    };
    this.urlArr = this.route.snapshot.pathFromRoot;
    this.currentPage = this.urlArr[3].url.join('');

    if (this.appService.user.role == '원장님') {
      this.pageDrive.teacher = true;
    } else if (this.appService.user.role == '정회원 선생님') {
      this.pageDrive.teacher = true;
    } else if (this.appService.user.role == '준회원 원장님') {
      this.pageDrive.teacher = true;
    } else if (this.appService.user.role == '준회원 선생님') {
      this.pageDrive.teacher = true;
    } else if (this.appService.user.role == '학생') {
      this.pageDrive.student = true;
    } else {
      this.pageDrive.before = true;
    }

    this.queryWrapper = {
      category: this.currentPage
    };
    this.varifyBookCategory();

    //for recorded audio file
    this.recordFileUploader = this.fileService.createFile();
    this.recordFileUploader.onCompleteAll = () => {
      this.recordFileUploader.clearQueue();
    };

    this.recordFileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let file = JSON.parse(response).file;
      this.recordedFile = file;
    };

    this.recordFileUploader.onErrorItem = (item, response, status, headers) => {
      this.dialogService.message("알림", '업로드에 실패하였습니다.');
    };

    this.init();
  }

  ngAfterViewInit(): void {
    this.initListeningAudio();
  }

  ngOnDestroy() {
    this.playPauseStopUnsubscribe.unsubscribe();
  }

  initListeningAudio() {
    setTimeout(() => {
      let audio = this.listeningAudio.nativeElement;
      audio.onended = () => {
        this.finishToggle = true;
        audio.load();
      };
      audio.onplaying = () => {
        this.finishToggle = false;
      }
    }, 4000);
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  startRecording() {
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: MediaStream) {
    this.playTimer();
    this.recordingToggle = !this.recordingToggle;

    this.stream = stream;
    this.recordRTC = RecordRTC(stream, {
      type: 'audio',
      mimeType: 'audio/mpeg'
    });
    this.recordRTC.startRecording();
  }

  errorCallback(error) {
    if (error.name == 'PermissionDeniedError') {
      console.log(error);
      this.dialogService.message("알림", "마이크 액세스가 차단되었습니다. 크롬 주소창 상단에서 마이크 접근을 허용 해주세요.")
    }
  }

  stopRecording() {
    this.recordingToggle = false;
    this.stopTimer();
    this.applyToggle = true;
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processAudio.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
  }

  processAudio(audioVideoWebMURL) {
    let recording: HTMLAudioElement = this.recording.nativeElement;
    let recordRTC = this.recordRTC;
    recording.src = audioVideoWebMURL;

    let recordedBlob = recordRTC.getBlob();
    this.uploadRecordedFile(recordedBlob);
  }

  uploadRecordedFile(recordedBlob) {
    this.recordFileUploader.clearQueue();

    //user + bookname + trackname + date
    let today = new Date();
    let name = this.appService.user.identifier + "_" + this.selectedBook.category + "_" + today.toISOString() + ".mp3";
    let file = new File([recordedBlob], name);

    this.recordFileUploader.addToQueue([file]);
    this.recordFileUploader.uploadAll();
  }

  private setPlay(res: any) {
    (res.play) ? this.play = true : this.play = false;
  }

  playTimer() {
    this.timerService.playTimer();
  }

  pauseTimer() {
    this.recordingToggle = !this.recordingToggle;
    this.timerService.pauseTimer();
  }

  stopTimer() {
    this.timerService.stopTimer();
  }


  changeAction(param) {
    if (param == 'listeningOn') {
      this.actionCtrl = true;
    } else {
      this.actionCtrl = false;
    }
  }

  varifyBookCategory() {
    switch (this.currentPage) {
      case 'company-middleschoolenglish' : {
        this.bookCategory = "Middle school English";
        break;
      }
      case 'company-leveltest' : {
        this.bookCategory = "Level Test";
        break;
      }
      case 'company-wela' : {
        this.bookCategory = "WeLA";
        break;
      }
      case 'company-bestfriend' : {
        this.bookCategory = "Best Friend";
        break;
      }
      case 'company-story' : {
        this.bookCategory = "Story";
        break;
      }
      case 'company-phonics' : {
        this.bookCategory = "Phonics";
        break;
      }
      case 'company-vocabulary' : {
        this.bookCategory = "Vocabulary";
        break;
      }
      case 'company-reading' : {
        this.bookCategory = "Reading";
        break;
      }
      case 'company-writing' : {
        this.bookCategory = "Writing";
        break;
      }
      case 'company-listening' : {
        this.bookCategory = "Listening";
        break;
      }
      case 'company-newspaper' : {
        this.bookCategory = "News Paper";
        break;
      }
      case 'company-etc' : {
        this.bookCategory = "Etc";
        break;
      }
      case 'campus-middleschoolenglish' : {
        this.bookCategory = "Middle School English";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
      case 'campus-listening' : {
        this.bookCategory = "Listening";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
      case 'campus-reading' : {
        this.bookCategory = "Reading";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
      case 'campus-PhonicsVoca' : {
        this.bookCategory = "Phonics, Voca";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
      case 'campus-etc' : {
        this.bookCategory = "Etc";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
      case 'campus-specialrecording' : {
        this.bookCategory = "Special Recording";
        this.queryWrapper = {
          category: this.currentPage,
          campus: this.appService.user.campus._id
        };
        break;
      }
    }
  }

  bookChanged() {
    this.finishToggle = false;
    this.selectedTrack = this.selectedBook.audioFiles[0];
  }

  trackChanged() {
    this.finishToggle = false;
    this.initListeningAudio();
  }

  /*****************************
   *       helper functions
   *****************************/

  init() {
    this.loadBooks()
      .pipe(
        delay(300)
      ).subscribe(
        (data) => {
          this.books = data.books;
          if(this.books.length > 0) {
            this.selectedBook = this.books[0];
            this.selectedTrack = this.selectedBook.audioFiles[0];
          }
          this.finishLoad = true;
        },
        (err) => {
          this.finishLoad = true;
          this.dialogService.message("알림", "잘못된 요청입니다.")
            .subscribe(() => {
            });
        });
  }

  loadBooks() {
    return this.bookService.find({
      query: this.queryWrapper,
      populate: ['audioFiles', 'bookImage'],
      sort: {createdAt: -1}
    });
  }

  finishListening() {
    if (this.finishToggle) {
      let point = {
        action: 'Listening',
        book: this.selectedBook._id,
        listeningFile: this.selectedTrack._id,
        student: this.appService.user._id,
        class: this.appService.user.class
      };
      this.pointService.create(point)
        .pipe(
          finalize(() => {
            this.appService.sendEvent('needScore');
          })
        )
        .subscribe((result) => {
          this.dialogService.message("알림", "20포인트가 지급되었습니다.")
            .subscribe(() => {
              this.finishToggle = false;
            });
        }, (error) => {
          console.log(error);
          this.dialogService.message("알림", "잘못된 요청입니다.")
            .subscribe(() => {
            });
        })
    }
  }

  finishRecording() {
    let point = {
      action: 'Recording',
      book: this.selectedBook._id,
      listeningFile: this.selectedTrack._id,
      recordingFile: this.recordedFile._id,
      student: this.appService.user._id,
      class: this.appService.user.class
    };
    this.pointService.create(point)
      .pipe(
        finalize(() => {
          this.appService.sendEvent('needScore');
        })
      )
      .subscribe((result) => {
        this.dialogService.message("알림", "100포인트가 지급되었습니다.")
          .subscribe(() => {
            this.applyToggle = false;
            this.recordingToggle = false;
            let recording: HTMLAudioElement = this.recording.nativeElement;
            recording.src = '';
          });
      }, (error) => {
        console.log(error);
        this.dialogService.message("알림", "잘못된 요청입니다.")
          .subscribe(() => {
          });
      });
  }
}
