import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class Banner implements OnInit {

  banner: any = {};

  constructor(public dialogRef: MatDialogRef<Banner>) { }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}

  /*****************************
   *        util functions
   *****************************/

  close() {
    this.dialogRef.close();
  }

}
