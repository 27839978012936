import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {delay} from 'rxjs/operators';
import {PostService} from "../../../services/post.service/post.service";
import {ReplyService} from "../../../services/reply.service/reply.service";
import {CommentService} from "../../../services/comment.service/comment.service";
import {DialogService} from "../../../components/dialog-message/dialog-message.service";
import {FileValidateService} from "../../../services/file.validate.service/file.validate.service";
import {AppService} from "../../../services/app.service/app.service";

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetail implements OnInit {
  subscriber: any;

  currentUrl: any;

  //CRETAE, MODIFY (글쓰기, 수정)
  manageType: string;

  //POST, REPLY (detail에서 load할 model이 post인지 reply인지 구분)
  modelType: string;

  //post category
  category: any;

  modelId: number;
  previousPost: number;
  nextPost: number;

  disablePrevious: boolean = false;
  disableNext: boolean = false;

  title: any;
  subTitle: any;

  toggleComment: boolean = true;
  commentContent: string = "";

  model: any;
  photoArray: any;

  finishLoad: boolean = false;

  swiperConfig: SwiperConfigInterface;

  constructor(public appService: AppService,
              private postService: PostService,
              private replyService: ReplyService,
              private commentService: CommentService,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              public fileValidateService: FileValidateService,
              public router: Router,
              private sanitizer: DomSanitizer) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.subscriber = this.activatedRoute.params
      .subscribe(params => {
        this.category = params['category'];
        this.modelType = params['modelType'];
        this.modelId = +params['post'] || 0;
        this.currentUrl = this.router.url.split('-detail');

        this.photoArray = [];

        this.init();
      });
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  init() {
    if (this.modelType == 'POST') {
      this.loadPost();
      this.loadPreviousPost();
      this.loadNextPost();
    } else {
      this.loadReply();
    }

    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false
    };
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }


  gotoPreviousPost() {
    this.router.navigate([this.currentUrl[0] + '-detail', this.category, this.modelType, this.previousPost]);
  }

  gotoNextPost() {
    this.router.navigate([this.currentUrl[0] + '-detail', this.category, this.modelType, this.nextPost]);
  }

  gotoPostList() {
    this.router.navigate([this.currentUrl[0] + '-list']);
  }

  gotoPostManage() {
    this.router.navigate([this.currentUrl[0] + '-manage', this.category, this.manageType, this.modelType, this.modelId]);
  }

  checkYoutubePanel() {
    if (!this.model.videoLink || this.model.videoLink == 'https://www.youtube.com/embed/')
      return false;
    else
      return true;
  }

  changeTrustResourceUrl() {
    if (this.model.videoLink)
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.model.videoLink);
    else
      return null;
  }

  downloadFile(file) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = file.secure_url;
    link.download = file.fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadPost(): any {
    this.postService.findOne({
      query: {
        _id: this.modelId
      },
      populate: ['files', 'owner', 'videos',
        {path: "comments", match: {isDeleted: false}, populate: {path: "owner"}}
      ]
    }).pipe(
      delay(300)
    ).subscribe((result) => {
        this.model = {};
        this.model = result['post'];

        this.category = this.model.category;

        switch (this.model.category) {
          case 'notice' : {
            this.title = '공지사항';
            this.subTitle = '우리 캠퍼스만의 다양한 소식과 영어학습을 위한 공지사항';
            break;
          }
          case 'homework' : {
            this.title = '숙제 게시판';
            this.subTitle = '우리 캠퍼스만의 영어학습을 위한 숙제 게시판입니다.';
            break;
          }
          case 'essay' : {
            this.title = 'ESSAY 및 영작 게시판';
            this.subTitle = '이 곳은 에세이 숙제와 자유롭게 영작을 하는 공간입니다.';
            break;
          }
          case 'ourstory' : {
            this.title = '우리들의 이야기';
            this.subTitle = '친구들과 만들어가는 우리들만의 이야기';
            break;
          }
          case 'gallery' : {
            this.title = '캠퍼스 사진첩';
            this.subTitle = '우리 캠퍼스만의 이벤트 풍경을 담은 앨범';
            break;
          }
          case 'video' : {
            this.title = '동영상 갤러리';
            this.subTitle = '우리 캠퍼스만의 동영상 앨범';
            break;
          }
          case 'weladatacenter' : {
            this.title = '웰라 자료실';
            this.subTitle = '웰라잉글리쉬 본사에서 제공하는 다양한 영어학습 자료들';
            this.toggleComment = false;
            break;
          }
          case 'campusdatacenter' : {
            this.title = '캠퍼스 자료실';
            this.subTitle = '영어학습에 필요한 자료를 가득 담아놓은 캠퍼스만의 학습자료실';
            break;
          }
          case 'welanews' : {
            this.title = '웰라 소식';
            this.subTitle = '앞으로 더욱 더 발전하는 웰라잉글리쉬클래스가 되겠습니다.';
            break;
          }
          case 'sarangbang' : {
            this.title = '사랑방';
            this.subTitle = '웰라는 원장님의 소중한 의견을 기다립니다.';
            break;
          }
          case 'elementrydatacenter' : {
            this.title = '초등 수업 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            break;
          }
          case 'elementrygamecenter' : {
            this.title = '초등 게임 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            break;
          }
          case 'middlecenter' : {
            this.title = '중등 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            break;
          }
        }

        _.forEach(this.model.files, (file) => {
          let type = file.fileType.split('/')[0];
          if (type === 'image')
            this.photoArray.push(file);
        });

        _.forEach(this.model.comments, (comment) => {
          return comment.checkModify = false;
        });
        this.finishLoad = true;
      }, error => {
        this.finishLoad = true;
        switch (error.status) {
          case 403:
            this.dialogService.message("알림", "게시물을 볼 수 있는 권한이 없습니다.")
              .subscribe(() => {
                this.gotoPostList();
              });
            break;

          default:
            this.dialogService.message("알림", "해당 게시물을 불러오는데 실패하였습니다. 리스트를 새로고침하여 주시기 바랍니다.")
              .subscribe(() => {
                this.gotoPostList();
              });
            return;
        }
      });
  }

  loadPreviousPost(): any {
    let queryWrapper = {
      isDeleted: false,
      category: this.category,
      _id: {
        $lt: this.modelId
      }
    };

    if (this.category === 'notice' ||
      this.category === 'homework' ||
      this.category === 'essay' ||
      this.category === 'ourstory' ||
      this.category === 'video' ||
      this.category === 'campusdatacenter' ||
      this.category === 'gallery') {
      queryWrapper['campus'] = this.appService.user.campus._id;
    }

    console.log("queryWrapper::\n", queryWrapper);

    this.postService.find({
      query: queryWrapper,
      sort: {_id: -1},
      limit: 1
    })
      .subscribe((result) => {
        if (result.posts && result.posts.length > 0) {
          this.previousPost = result.posts[0]._id;
          this.disablePrevious = false;
        } else
          this.disablePrevious = true;
      });
  }

  loadNextPost(): any {
    let queryWrapper = {
      isDeleted: false,
      category: this.category,
      _id: {
        $gt: this.modelId
      }
    };

    if (this.category == 'notice' ||
      this.category == 'homework' ||
      this.category == 'essay' ||
      this.category == 'ourstory' ||
      this.category == 'video' ||
      this.category == 'campusdatacenter' ||
      this.category === 'gallery') {
      queryWrapper['campus'] = this.appService.user.campus._id;
    }

    this.postService.find({
      query: queryWrapper,
      sort: {_id: 1},
      limit: 1
    })
      .subscribe((result) => {
        if (result.posts && result.posts.length > 0) {
          this.nextPost = result.posts[0]._id;
          this.disableNext = false;
        } else
          this.disableNext = true;
      });
  }

  modifyPost() {
    this.manageType = 'MODIFY';
    this.gotoPostManage();
  }

  removePost() {
    if (this.appService.user.role === '관리자' || this.model.owner._id == this.appService.user._id) {
      this.dialogService.confirm("알림", "해당 게시글을 삭제하시겠습니까? 게시글을 삭제하면 작성된 답글도 삭제됩니다.")
        .subscribe((result) => {
          if (result) {
            this.postService.remove(this.model._id)
              .subscribe((result) => {
                this.dialogService.message("알림", "게시글이 삭제되었습니다\n")
                  .subscribe(() => {
                    this.gotoPostList();
                  });
              }, error => {
                console.log("error :::\n", error);
                this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
              })
          }
        });
    }
  }

  /**
   * for Reply
   */

  loadReply() {
    this.replyService.findOne({
      query: {
        _id: this.modelId
      },
      populate: ['files', 'owner', {path: "comments", match: {isDeleted: false}, populate: {path: "owner"}}]
    }).pipe(
        delay(300)
      ).subscribe((result) => {
        this.model = result['reply'];
        this.category = this.model.category;

        switch (this.model.category) {
          case 'notice' :
            this.title = '공지사항';
            this.subTitle = '우리 캠퍼스만의 다양한 소식과 영어학습을 위한 공지사항';
            break;
          case 'homework' :
            this.title = '숙제 게시판';
            this.subTitle = '우리 캠퍼스만의 영어학습을 위한 숙제 게시판입니다.';
            break;
          case 'essay' :
            this.title = 'ESSAY 및 영작 게시판';
            this.subTitle = '이 곳은 에세이 숙제와 자유롭게 영작을 하는 공간입니다.';
            break;
          case 'ourstory' :
            this.title = '우리들의 이야기';
            this.subTitle = '친구들과 만들어가는 우리들만의 이야기';
            break;
          case 'video' :
            this.title = '동영상 갤러리';
            this.subTitle = '우리 캠퍼스만의 동영상 앨범';
            break;
        }

        _.forEach(this.model.files, (file) => {
          let type = file.fileType.split('/')[0];
          if (type === 'image')
            this.photoArray.push(file);
        });

        _.forEach(this.model.comments, (comment) => {
          return comment.checkModify = false;
        });
        this.finishLoad = true;
      }, error => {
        this.finishLoad = true;
        switch (error.status) {
          case 403:
            this.dialogService.message("알림", "게시물을 볼 수 있는 권한이 없습니다.")
              .subscribe(() => {
                this.gotoPostList();
              });
            break;

          default:
            this.dialogService.message("알림", "해당 게시물을 불러오는데 실패하였습니다. 리스트를 새로고침하여 주시기 바랍니다.");
            return;
        }
      });
  }

  makeReply() {
    this.manageType = 'CREATE';
    this.modelType = 'REPLY';
    this.gotoPostManage();
  }

  modifyReply() {
    this.manageType = 'MODIFY';
    this.modelType = 'REPLY';
    this.gotoPostManage();
  }

  removeReply() {
    if (this.model.owner._id == this.appService.user._id) {
      this.dialogService.confirm("알림", "해당 답글을 삭제하시겠습니까?")
        .subscribe((result) => {
          if (result) {
            this.replyService.remove(this.model._id)
              .subscribe((result) => {
                this.dialogService.message("알림", "답글이 삭제되었습니다\n")
                  .subscribe(() => {
                    this.gotoPostList();
                  });
              }, error => {
                console.log("error :::\n", error);
                this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
              })
          }
        });
    }
  }


  /**
   * for Comment
   */

  applyComment() {
    if (this.commentContent && this.commentContent != "") {
      let comment = {
        content: this.commentContent,
        model: this.modelId,
        modelType: this.modelType
      };

      this.commentService.create(comment)
        .subscribe((result) => {
          this.dialogService.message("알림", "댓글이 등록되었습니다\n")
            .subscribe(() => {
              this.commentContent = "";
              this.init();
            });
        }, error => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
        })
    } else {
      this.dialogService.message("알림", "댓글 내용을 작성해주세요.");
    }
  }

  toggleCommentButton(comment, action) {
    comment.checkModify = !comment.checkModify;
    if(action === 'cancel')
      this.init();
  }

  modifyComment(comment) {
    if (comment.content && comment.content != "") {
      delete comment.checkModify;
      this.commentService.update(comment)
        .subscribe((result) => {
          this.dialogService.message("알림", "댓글이 수정되었습니다\n")
            .subscribe(() => {
              this.init();
            });
        }, error => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
        })
    }
  }

  removeComment(comment) {
    if (this.appService.user.role === '관리자' || (comment.owner._id == this.appService.user._id)) {
      this.dialogService.confirm("알림", "해당 댓글을 삭제하시겠습니까?")
        .subscribe((result) => {
          if (result) {
            this.commentService.remove(comment._id)
              .subscribe((result) => {
                this.dialogService.message("알림", "댓글이 삭제되었습니다\n")
                  .subscribe(() => {
                    this.init();
                  });
              }, error => {
                console.log("error :::\n", error);
                this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
              });
          }
        });
    }
  }
}
