import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {CampusService} from "../../services/campus.service/campus.service";

@Component({
  selector: 'app-wela-brand',
  templateUrl: './wela-brand.component.html',
  styleUrls: ['./wela-brand.component.scss']
})
export class WelaBrand implements OnInit {
  campus = {};

  constructor(public appService: AppService,
              private campusService: CampusService,
              private dialogService: DialogService,
              public router: Router) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    if(this.appService.user)
      this.loadCampus();
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  gotoExternalLink(param) {
    switch(param) {
      case 'wela-times-academy' : {
        window.open("http://timesacademy.kr", "_blank");
        break;
      }
      case 'ne-times' : {
        window.open("http://www.netimes.co.kr", "_blank");
        break;
      }
      case 'campus-link' : {
        window.open(this.campus['linkUrl'], "_blank");
        break;
      }
    }
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      }
    })
      .subscribe((result) => {
        this.campus = result.campus;
      }, error => {
        console.log("error :::\n", error);
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
      });
  }
}
