import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-usage-policy',
  templateUrl: './usage-policy.component.html',
  styleUrls: ['./usage-policy.component.scss']
})
export class UsagePolicy {

  constructor(public dialogRef: MatDialogRef<UsagePolicy>) {
  }

  /*****************************
   *         life cycle
   *****************************/

  /*****************************
   *        util functions
   *****************************/

  close() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/
}
