import {Injectable} from '@angular/core';
import {FileUploader} from "ng2-file-upload";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private serverUrl = environment.serverUrl;

  constructor() {}

  createFile(): FileUploader {
    return new FileUploader({
      url: this.serverUrl + '/file'
    });
  }

  createVideo(): FileUploader {
    return new FileUploader({
      url: this.serverUrl + '/video'
    });
  }
}
