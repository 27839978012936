import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {AppService} from '../services/app.service/app.service';

@Injectable()
export class headerInterceptor implements HttpInterceptor {
  constructor(private appService: AppService,
              private _router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.appService.token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.appService.token) });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json, text/plain, */*'),
      withCredentials: true
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // return event.body;
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.appService.user = null;
          this.appService.token = null;
          this._router.navigateByUrl('before-login/intro');
        }
        throw error;
      }));
  }
}
