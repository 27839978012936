import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {CampusService} from "../../services/campus.service/campus.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {PostService} from "../../services/post.service/post.service";
import {delay} from 'rxjs/operators';
import {RouteService} from '../../services/route.service/route.service';

@Component({
  selector: 'app-community-photo',
  templateUrl: './community-photo.component.html',
  styleUrls: ['./community-photo.component.scss']
})
export class CommunityPhoto implements OnInit {
  selectedQueryType: any;
  queryTypes: any;
  queryString: string;

  galleryCategories: any = [];
  selectedCategory: string = '';

  writeCheck: boolean = false;

  urlArray: any;
  currentPost: any;

  queryParams: any;
  queryWrapper:any;
  public options = {
    rows: [],
    selected: null,
    headerHeight: "56px",
    footerHeight: "80px",
    rowHeight: "80px",
    bodyHeight: "800px",
    limit: 9,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              private campusService: CampusService,
              private dialogService: DialogService,
              private postService: PostService,
              private router: Router,
              private routeService: RouteService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.urlArray = this.router.url.split('/');
    let lastUrl = _.last(this.urlArray);
    let lastUrls = lastUrl.split('-');
    this.currentPost = lastUrls[0];

    if (this.appService.user.role == "학생") this.writeCheck = false;
    else if (this.appService.user.role == "원장님") this.writeCheck = true;
    else if (this.appService.user.role == "정회원 선생님") this.writeCheck = true;
    else if (this.appService.user.role == "준회원 원장님") this.writeCheck = true;
    else if (this.appService.user.role == "준회원 선생님") this.writeCheck = true;


    this.selectedQueryType = "제목";
    this.queryTypes = ['제목', '제목+내용', '작성자'];
    this.queryString = "";

    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      category: 'gallery'
    };

    this.loadCampus();
    this.loadList({page: this.options.page});
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  varifyCategory(category?) {
    this.options.page = 1;

    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      category: 'gallery',
      galleryCategory: category
    };

    if (category) {
      this.selectedCategory = category;
    } else {
      this.selectedCategory = '';
    }

    this.loadList({page: this.options.page});
  }

  gotoPostDetail(post, modelType) {
    let path = "";
    for (let i = 0; i < this.urlArray.length - 1; i++) {
      path += this.urlArray[i] + '/'
    }

    this.router.navigate([path + this.currentPost + '-detail', this.currentPost, modelType, post.id]);
  }

  gotoManage(modelType) {
    let path = "";
    for (let i = 0; i < this.urlArray.length - 1; i++) {
      path += this.urlArray[i] + '/'
    }

    let manageType = 'CREATE';
    this.router.navigate([path + this.currentPost + '-manage', this.currentPost, manageType, modelType, ""]);
  }

  searchByQuery() {
    if (this.queryString && this.queryString != '') {
      switch (this.selectedQueryType) {
        case '제목' : {
          this.queryWrapper.title = {$regex: ".*" + this.queryString + ".*"};
          this.loadList({page: this.options.page});
          break;
        }
        case '제목+내용' : {
          let otherQuery =
            {
              "$or": [
                {"title": {$regex: ".*" + this.queryString + ".*"}},
                {"contentHtml": {$regex: ".*" + this.queryString + ".*"}}
              ]
            };

          _.merge(this.queryWrapper, otherQuery);
          this.loadList({page: this.options.page});
          break;
        }
        case'작성자': {
          this.queryWrapper.name = this.queryString;
          this.loadList({page: this.options.page});
          break;
        }
      }
    } else {
      this.dialogService.message("알림", "검색어를 입력해주세요")
        .subscribe(() => {
        });
    }
  }

  checkUrl () {
    // TODO: -manage 갔다가 list 갈 경우 잘못된 쿼리를 날림
    const splitedString = _.split(this.routeService.getPreviousUrl(), '/', 4);

    return (splitedString[3] === this.currentPost) &&
      (this.routeService.getPreviousUrl().includes('-detail') || this.routeService.getPreviousUrl().includes('-manage'))
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      }
    })
      .subscribe((result) => {
        this.galleryCategories = result.campus.galleryCategories;
      }, error => {
        console.log("error :::\n", error);
        this.finishLoad = true;
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.");
      });
  }

  loadList(event) {
    this.finishLoad = false;
    this.options.rows = [];
    this.appService.sendEvent('scrollTop');
    this.options.page = event.page;

    if(this.checkUrl()) {
      this.queryParams = this.postService.getPostQueryParams();
      this.options.page = this.postService.getPostPage();
      this.selectedCategory = this.postService.getGalleryCaterogy();
      if(this.postService.getGalleryCaterogy() !== "")
        this.queryWrapper.galleryCategory = this.postService.getGalleryCaterogy();

      this.postService.initPostParams();
      this.routeService.previousUrl = "";
    } else {
      this.queryParams = {
        query: this.queryWrapper,
        populate: ['files'],
        limit: this.options.limit,
        skip: (this.options.page - 1) * this.options.limit,
        sort: {_id: -1}
      }
    }

    this.postService.setPostQueryParams(this.queryParams);
    this.postService.setPostPage(this.options.page);
    this.postService.setGalleryCaterogy(this.selectedCategory);

    this.postService.find(this.queryParams)
      .pipe(
        delay(300)
      ).subscribe(
        (data) => {
          this.options.rows = data.posts;
          this.options.count = data.total;
          this.finishLoad = true;
        },
        (error) => {
          console.log("error::\n", error);
          this.finishLoad = true;
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.");
        }
      );
  }
}
