declare var _;
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  private serverUrl = environment.serverUrl + '/book';

  public bookCateogories = [
    {title: 'Middle School English', value: 'company-middleschoolenglish'},
    {title: 'Level Test', value: 'company-leveltest'},
    {title: 'WeLA', value: 'company-wela'},
    {title: 'Best Friend', value: 'company-bestfriend'},
    {title: 'Story', value: 'company-story'},
    {title: 'Phonics', value: 'company-phonics'},
    {title: 'Vocabulary', value: 'company-vocabulary'},
    {title: 'Reading', value: 'company-reading'},
    {title: 'Writing', value: 'company-writing'},
    {title: 'Listening', value: 'company-listening'},
    {title: 'Newspaper', value: 'company-newspaper'},
    {title: 'Etc', value: 'company-etc'},
    {title: 'Middle School English', value: 'campus-middleschoolenglish'},
    {title: 'Listening', value: 'campus-listening'},
    {title: 'Reading', value: 'campus-reading'},
    {title: 'Phonics, Voca', value: 'campus-PhonicsVoca'},
    {title: 'Etc', value: 'campus-etc'},
    {title: 'Special Recording', value: 'campus-specialrecording'}
  ];

  constructor(private httpClient: HttpClient) {}

  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params = new HttpParams();
    _.forEach(queryParams, (value, key)=> {
      params = params.append(key, JSON.stringify(value));
    });

    return this.httpClient.get(url, {params: params});
  }

  create(params: Object): Observable<any> {
    return this.httpClient
      .post(this.serverUrl, params);
  }

  update(params: Object): Observable<any> {
    return this.httpClient
      .put(this.serverUrl, params);
  }

  remove(_id: string): Observable<any> {
    let httpParams = new HttpParams().set('_id', _id);
    let options = { params: httpParams };

    return this.httpClient
      .delete(this.serverUrl, options);
  }

  findCategory(getCategory) {
    return _.find(this.bookCateogories, (category => {
      return category.value == getCategory;
    }));
  }
}
