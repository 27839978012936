import * as _ from 'lodash';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {PostService} from "../../../services/post.service/post.service";
import {ReplyService} from "../../../services/reply.service/reply.service";
import {ClassService} from "../../../services/class.service/class.service";
import {DialogService} from "../../../components/dialog-message/dialog-message.service";
import {FileService} from "../../../services/file.service/file.service";
import {FileValidateService} from "../../../services/file.validate.service/file.validate.service";
import {AppService} from "../../../services/app.service/app.service";
import {CampusService} from "../../../services/campus.service/campus.service";

@Component({
  selector: 'app-post-manage',
  templateUrl: './post-manage.component.html',
  styleUrls: ['./post-manage.component.scss']
})
export class PostManage implements OnInit {
  subscriber: any;

  fileUploader;
  videoUploader;
  toggleVideo: boolean = false;
  toggleInput: boolean = false;
  videoUploadFinished: boolean = true;

  manageType: string;
  modelType: string;

  //캠퍼스의 클래스 관련 Object --> Essay 및 영작게시판, 숙제게시판에서 클래스분류를 위해 사용
  classes: any;
  selectedClass: any;

  //캠퍼스 사진첩 카테고리 관련 Object
  galleryCategories: any;
  selectedGalleryCategory: any;

  //동영상 갤러리 카테고리 관련 Object
  videoCategories: any;
  selectedVideoCategory: any;

  postId: any;
  post: any = {
    files: [],
    videos: [],
    contentHtml: ''
  };

  title: any;
  subTitle: any;

  editorConfig = {
    selector: "PostManage"
  };

  baseUrl: string = "https://www.youtube.com/embed/";

  constructor(public appService: AppService,
              private postService: PostService,
              private replyService: ReplyService,
              public classService: ClassService,
              private campusService: CampusService,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private fileService: FileService,
              public fileValidateService: FileValidateService,
              public router: Router,
              private location: Location) {
  }

  /*****************************
   *         life cycle
   *****************************/



  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriber = this.activatedRoute.params
      .subscribe(params => {
        this.post.category = params['category'];
        this.manageType = params['manageType'];
        this.modelType = params['modelType'];
        this.postId = +params['post'] || 0;

        this.post.campus = this.appService.user.campus._id;
        this.post.isSecret = false;

        if (this.modelType == 'POST') {
          if (this.manageType == 'MODIFY')
            this.loadPost();
        } else {
          if (this.manageType == 'MODIFY')
            this.loadReply();
        }

        switch (this.post.category) {
          case 'notice' : {
            this.title = '공지사항';
            this.subTitle = '우리 캠퍼스만의 다양한 소식과 영어학습을 위한 공지사항';
            break;
          }
          case 'homework' : {
            this.title = '숙제 게시판';
            this.subTitle = '우리 캠퍼스만의 영어학습을 위한 숙제 게시판입니다.';
            this.loadClass();
            break;
          }
          case 'essay' : {
            this.title = 'ESSAY 및 영작 게시판';
            this.subTitle = '이 곳은 에세이 숙제와 자유롭게 영작을 하는 공간입니다.';
            this.loadClass();
            break;
          }
          case 'ourstory' : {
            this.title = '우리들의 이야기';
            this.subTitle = '친구들과 만들어가는 우리들만의 이야기';
            this.toggleVideo = true;
            break;
          }
          case 'gallery' : {
            this.title = '캠퍼스 사진첩';
            this.subTitle = '우리 캠퍼스만의 이벤트 풍경을 담은 앨범';
            this.loadCampus();
            break;
          }
          case 'video' : {
            this.title = '동영상 갤러리';
            this.subTitle = '우리 캠퍼스만의 동영상 앨범';
            this.toggleVideo = true;
            this.loadCampus();
            break;
          }
          case 'weladatacenter' : {
            this.title = '웰라 자료실';
            this.subTitle = '웰라잉글리쉬 본사에서 제공하는 다양한 영어학습 자료들';
            this.toggleVideo = true;
            break;
          }
          case 'campusdatacenter' : {
            this.title = '캠퍼스 자료실';
            this.subTitle = '영어학습에 필요한 자료를 가득 담아놓은 캠퍼스만의 학습자료실';
            this.toggleVideo = true;
            break;
          }
          case 'welanews' : {
            this.title = '웰라 소식';
            this.subTitle = '앞으로 더욱 더 발전하는 웰라잉글리쉬클래스가 되겠습니다.';
            break;
          }
          case 'sarangbang' : {
            this.title = '사랑방';
            this.subTitle = '웰라는 원장님의 소중한 의견을 기다립니다.';
            this.toggleVideo = true;
            break;
          }
          case 'elementrydatacenter' : {
            this.title = '초등 수업 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            this.toggleVideo = true;
            break;
          }
          case 'elementrygamecenter' : {
            this.title = '초등 게임 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            this.toggleVideo = true;
            break;
          }
          case 'middlecenter' : {
            this.title = '중등 자료실';
            this.subTitle = '다양한 교육 자료로 퀄리티 높은 수업을 만들어보세요.';
            this.toggleVideo = true;
            break;
          }
        }
        this.initFileUploader();
        this.initVideoUploader();
      });
  }

  initFileUploader() {
    /**
     * init for fileUploader
     */
    this.fileUploader = this.fileService.createFile();

    this.fileUploader.onCompleteAll = () => {
      this.fileUploader.clearQueue();
    };

    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if(response !== "" && status === 200) {
        let file = JSON.parse(response).file;
        file.check = false;
        this.post.files.push(file);
      }
    };
    this.fileUploader.onErrorItem = (item, response, status, headers) => {
      this.dialogService.message("알림", '업로드에 실패하였습니다.');
      this.videoUploadFinished = true;
    };
  }

  initVideoUploader() {
    this.videoUploader = this.fileService.createVideo();

    this.videoUploader.onCompleteAll = () => {
      this.videoUploadFinished = true;
      this.videoUploader.clearQueue();
    };

    this.videoUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if(response !== "" && status === 200) {
        let video = JSON.parse(response).file;
        this.post.videos.push(video);

        this.videoUploadFinished = true;
      }
    };
    this.videoUploader.onErrorItem = (item, response, status, headers) => {
      this.dialogService.message("알림", '업로드에 실패하였습니다.');
    };
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  selectCategory(value) {
    if(this.post.category == 'gallery')
      this.selectedGalleryCategory = value;
    else if(this.post.category == 'video')
      this.selectedVideoCategory = value;
  }

  uploadFile(event) {
    event.target.value = null;

    let errorType = this.fileValidateService.checkFileError(this.fileUploader.queue, this.post.files.length, 1, 10);
    if (!errorType) {
      return this.fileUploader.uploadAll();
    }

    switch (errorType) {
      case 'max-count' :
        this.dialogService.message("알림", '파일은 최대 ' + 10 + '개 까지만 등록가능합니다.');
        this.videoUploader.clearQueue();
        break;
      case 'file-size' :
        this.dialogService.message("알림", '파일 용량의 최대 사이즈는 15MB입니다.');
        this.videoUploader.clearQueue();
        break;
    }
    this.fileUploader.clearQueue();
  }

  uploadVideo(event) {
    event.target.value = null;

    this.dialogService.confirm("알림", "동영상 업로드시 1분 ~ 5분의 시간이 소요됩니다. 동영상을 업로드 하시겠습니까?")
      .subscribe((result) => {
        if (result) {
          this.post.videos = [];
          let errorType = this.fileValidateService.checkVideoError(this.videoUploader.queue, this.post.videos.length);
          if (!errorType) {
            this.videoUploadFinished = false;
            return this.videoUploader.uploadAll();
          }

          switch (errorType) {
            case 'max-count' :
              this.dialogService.message("알림", '비디오 파일은 최대 ' + 1 + '개 까지만 등록가능합니다.');
              break;
            case 'file-size' :
              this.dialogService.message("알림", '비디오 파일 용량의 최대 사이즈는 200MB입니다.');
              break;
            case 'file-format' :
              this.dialogService.message("알림", 'mp4, MOV 파일만 업로드 가능합니다. 비디오 파일 확장자를 확인해주세요.');
              break;
          }

          this.videoUploader.clearQueue();
        }
      });
  }

  //only for campus-gallery
  uploadPhoto(event) {
    event.target.value = null;
    let errorType = this.fileValidateService.checkPhotoError(this.fileUploader.queue, this.post.files.length, 1, 10);
    if (!errorType) {
      return this.fileUploader.uploadAll();
    }

    switch (errorType) {
      case 'max-count' :
        this.dialogService.message("알림", '이미지는 최대 ' + 10 + '장 까지만 등록가능합니다.');
        break;
      case 'file-size' :
        this.dialogService.message("알림", '이미지 용량의 최대 사이즈는 15MB입니다.');
        break;
      case 'file-format' :
        this.dialogService.message("알림", '캠퍼스 사진첩에는 이미지 파일만 등록가능합니다.');
        break;
    }

    this.fileUploader.clearQueue();
  }

  toggleFile(file) {
    file.check = !file.check;
  }

  checkAllfileToggle() {
    if (this.post.files.length != 0) {
      for (let i = 0; i < this.post.files.length; i++) {
        if (!this.post.files[i].check)
          return false;
      }
      return true;
    } else {
      return false;
    }
  }

  allFileToggle() {
    if (this.checkAllfileToggle()) {
      _.forEach(this.post.files, (file) => {
        file.check = false;
      })
    } else {
      _.forEach(this.post.files, (file) => {
        file.check = true;
      })
    }

  }

  removeFile() {
    _.remove(this.post.files, (file) => {
      return file.check == true;
    });
  }

  gotoPostDetail() {
    let array = this.router.url.split('-manage');
    this.router.navigate([array[0] + '-detail', this.post.category, this.modelType, this.postId]);
  }

  gotoPostList() {
    let array = this.router.url.split('-manage');
    this.router.navigate([array[0] + '-list']);
  }

  isValid(): any {
    if (!this.post.title) {
      return '제목을 입력해 주세요';
    } else if (this.post.title == '') {
      return '제목을 입력해 주세요';
    } else if (!this.post.contentHtml) {
      return '내용을 입력해 주세요';
    } else if (this.post.contentHtml == '') {
      return '내용을 입력해 주세요';
    } else if (this.post.category === 'gallery' && this.post.files.length === 0) {
      return '사진을 업로드 해주세요';
    } else return true;
  }

  checkMethod() {
    let valid = this.isValid();

    if(valid == true) {
      if (this.modelType == 'POST') {
        if (this.manageType == 'CREATE') return this.createPost();
        else if (this.manageType == 'MODIFY') return this.modifyPost();
      } else {
        if (this.manageType == 'CREATE') return this.createReply();
        else if (this.manageType == 'MODIFY') return this.modifyReply();
      }
    } else {
      this.dialogService.message("알림", valid);
    }
  }

  showInput() {
    this.toggleInput = !this.toggleInput;
    this.post.videoLink = this.baseUrl;
  }

  cancel() {
    this.location.back();
  }

  compareClassId(option, value) {
    return option.id === value.id;
  }

  /*****************************
   *       helper functions
   *****************************/

  loadClass() {
    let params = {
      query: {
        campus: this.appService.user.campus._id
      }
    };

    this.classService.find(params)
      .subscribe((data) => {
          this.classes = data.classes;
          this.selectedClass = this.classes[0];
        }, (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다")
        }
      )
  }

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      }
    })
      .subscribe((result) => {
        if (this.post.category == 'gallery') {
          this.galleryCategories = result.campus.galleryCategories;
        } else if(this.post.category == 'video') {
          this.videoCategories = result.campus.videoCategories;
        }
      }, error => {
        console.log("error :::\n", error);
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.");
      });
  }

  loadPost(): any {
    this.postService.findOne({
      query: {
        isDeleted: false,
        category: this.post.category,
        campus: this.appService.user.campus._id,
        _id: this.postId
      },
      populate: ['files', 'owner', 'videos', {path: "comments", match: {isDeleted: false}, populate: {path: "owner"}}]
    })
      .subscribe((result) => {
        this.toggleInput = true;
        this.post = result['post'];

        _.forEach(this.classes, classItem => {
          if (classItem._id === this.post.class)
            this.selectedClass = classItem;
        });

        if(!this.post.videos)
          this.post.videos = [];

        if (!this.post.videoLink || this.post.videoLink.indexOf('https://www.youtube.com/embed/') < 0) {
          this.post.videoLink = this.baseUrl;
        }

        if (this.post.category == 'gallery') {
          if(this.post.galleryCategory)
            this.selectedGalleryCategory = this.post.galleryCategory;
        } else if(this.post.category == 'video') {
          if(this.post.videoCategory)
            this.selectedVideoCategory = this.post.videoCategory;
        }
      }, error => {
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
      });
  }

  /**
   * for Post
   */

  createPost() {
    if (this.post.category === "homework" || this.post.category === "essay") {
      this.post.class = this.selectedClass._id;
    } else if (this.post.category === 'gallery') {
      this.post.galleryCategory = this.selectedGalleryCategory;
    } else if(this.post.category === 'video') {
      this.post.videoCategory = this.selectedVideoCategory;
    }

    this.postService.create(this.post)
      .subscribe((result) => {
        this.dialogService.message("알림", "게시글이 등록되었습니다.")
          .subscribe(() => {
            this.gotoPostList();
          });
      }, (error) => {
        this.dialogService.message("알림", "게시글을 등록하는데 실패하였습니다.")
          .subscribe(() => {
          });
      })
  }

  modifyPost() {
    if (this.post.category === "homework" || this.post.category === "essay") {
      this.post.class = this.selectedClass._id;
    } else if (this.post.category === 'gallery') {
      this.post.galleryCategory = this.selectedGalleryCategory;
    } else if (this.post.category === 'video') {
      this.post.videoCategory = this.selectedVideoCategory;
    }

    this.postService.update(this.post)
      .subscribe((result) => {
        this.dialogService.message("알림", "게시글이 수정되었습니다.")
          .subscribe(() => {
            this.gotoPostDetail();
          });
      }, (error) => {
        this.dialogService.message("알림", "게시글을 수정하는데 실패하였습니다.")
          .subscribe(() => {
          });
      })
  }

  /**
   * for Reply
   */

  loadReply(): any {
    this.replyService.findOne({
      query: {
        isDeleted: false,
        category: this.post.category,
        campus: this.appService.user.campus._id,
        _id: this.postId
      },
      populate: ['files', 'owner', {path: "comments", match: {isDeleted: false}, populate: {path: "owner"}}]
    })
      .subscribe((result) => {
        this.toggleInput = true;
        this.post = result['reply'];
        if (!this.post.videoLink || this.post.videoLink.indexOf('https://www.youtube.com/embed/') < 0) {
          this.post.videoLink = this.baseUrl;
        }
      }, error => {
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
      });
  }

  createReply() {
    let reply = this.post;
    reply.postId = this.postId;

    this.replyService.create(reply)
      .subscribe((result) => {
        this.dialogService.message("알림", "답글이 등록되었습니다.")
          .subscribe(() => {
            this.gotoPostList();
          });
      }, (error) => {
        this.dialogService.message("알림", "답글을 등록하는데 실패하였습니다.")
          .subscribe(() => {
          });
      })
  }

  modifyReply() {
    let reply = this.post;
    reply.postId = this.postId;

    this.replyService.update(this.post)
      .subscribe((result) => {
        this.dialogService.message("알림", "답글이 수정되었습니다.")
          .subscribe(() => {
            this.gotoPostDetail();
          });
      }, (error) => {
        this.dialogService.message("알림", "답글을 수정하는데 실패하였습니다.")
          .subscribe(() => {
          });
      })
  }
}
