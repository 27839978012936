import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileValidateService {

  constructor() {}

  /**
   * 업로드 파일 갯수 확인
   * min default: 1, max default: 1
   * @return boolean
   */

  checkFileError(files: any, photoLength?: any, min?: number, max?: number): any {
    let minCount = min ? min : 1;
    let maxCount = max ? max : 1;

    let totalLength = photoLength ? photoLength : 0;
    totalLength += files.length;

    if (files.length < minCount) return 'min-count';
    if (totalLength > maxCount) return 'max-count';
    if (!this.fileSizeValidate(files)) return 'file-size';

    return false;
  }

  checkPhotoError(files: any, photoLength?: any, min?: number, max?: number): any {
    let minCount = min ? min : 1;
    let maxCount = max ? max : 1;

    let totalLength = photoLength ? photoLength : 0;
    totalLength += files.length;

    if (files.length < minCount) return 'min-count';
    if (totalLength > maxCount) return 'max-count';
    if (!this.imageFilesValidate(files)) return 'file-format';
    if (!this.fileSizeValidate(files)) return 'file-size';

    return false;
  }

  checkVideoError(files: any, fileLength?: any, min?: number, max?: number): any {
    let minCount = min ? min : 1;
    let maxCount = max ? max : 1;

    let totalLength = fileLength ? fileLength : 0;
    totalLength += files.length;

    if (files.length < minCount) return 'min-count';
    if (totalLength > maxCount) return 'max-count';
    if (!this.videoSizeValidate(files)) return 'file-size';
    if (!this.videoFilesValidate(files)) return 'file-format';

    return false;
  }

  /**
   * 업로드 파일 이미지 여부 확인
   * @return boolean
   */
  imageFilesValidate(files: any): boolean {
    let i;
    for (i = 0; i < files.length; i++) {
      if (!files[i].file.name.match(/\.(jpe?g|png|gif|bmp)$/i)) {
        break;
      }
    }

    if (i == files.length) {
      return true;
    } else {
      return false;
    }
  }

  videoFilesValidate(files: any): boolean {
    let i;
    for (i = 0; i < files.length; i++) {
      if (!files[i].file.name.match(/\.(mp4|MOV)$/i)) {
        break;
      }
    }

    if (i == files.length) {
      return true;
    } else {
      return false;
    }
  }

  imageFileValidate(file: any): boolean {
    let i;
    if (file.fileName.match(/\.(jpe?g|png|gif|bmp)$/i)) return true;
    else return false;
  }

  /**
   * 업로드 파일 사이즈 확인
   * Default limit 4MB
   * @return boolean
   */
  fileSizeValidate(files: any, size?: number): boolean {
    let maxSize = size ? size : 1048576 * 15;

    let i;
    for (i = 0; i < files.length; i++) {
      if (files[i]._file.size >= maxSize) {
        return false;
      }
    }

    return true;
  }

  videoSizeValidate(files: any, size?: number): boolean {
    let maxSize = size ? size : 1048576 * 200;

    let i;
    for (i = 0; i < files.length; i++) {
      if (files[i]._file.size >= maxSize) {
        return false;
      }
    }

    return true;
  }
}
