import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-private-policy',
  templateUrl: './private-policy.component.html',
  styleUrls: ['./private-policy.component.scss']
})
export class PrivatePolicy {

  constructor(public dialogRef: MatDialogRef<PrivatePolicy>) {}

  /*****************************
   *         life cycle
   *****************************/

  /*****************************
   *        util functions
   *****************************/

  close() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/
}
