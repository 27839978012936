import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {AuthService} from "../../services/auth.service/auth.service";

@Component({
  selector: 'app-join-member',
  templateUrl: './join-member.component.html',
  styleUrls: ['./join-member.component.scss']
})
export class JoinMember implements OnInit {

  mailObj: any;

  regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  phone: any;
  mobile: any;

  constructor(public appService: AppService,
              public router: Router,
              private dialogService: DialogService,
              private authService: AuthService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnDestroy() {
  }

  ngOnInit(): void {
    this.mailObj = {};

    this.phone = [];
    this.mobile = [];
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  isValid() {
    let valid = true, msg;

    if (!this.mailObj.name || this.mailObj.name === '') {
      valid = false;
      msg = "성함을 입력해 주세요";
    } else if (this.phone.length != 3 || (this.phone[0] == '' || this.phone[1] == '' || this.phone[2] == '')) {
      valid = false;
      msg = "전화번호 항목을 모두 입력해 주세요.";
    } else if (this.mobile.length != 3 || (this.mobile[0] == '' || this.mobile[1] == '' || this.mobile[2] == '')) {
      valid = false;
      msg = "휴대폰 항목을 모두 입력해 주세요.";
    } else if (!this.mailObj.email) {
      valid = false;
      msg = "이메일을 입력해주세요.";
    } else if (!this.regExp.test(this.mailObj.email)) {
      valid = false;
      msg = "이메일 형식이 맞지 않습니다. 이메일 형식으로 입력해주세요.";
    } else if (!this.mailObj.content) {
      valid = false;
      msg = "경력 정보를 입력해주세요.";
    }

    if (valid == false) {
      this.dialogService.message("알림", msg);
    } else
      return true;
  }

  /*****************************
   *       helper functions
   *****************************/

  submit() {
    if (this.isValid()) {
      this.mailObj.phone = this.phone.join('-');
      this.mailObj.mobile = this.mobile.join('-');
      this.mailObj.category = 'support';

      this.authService.support(this.mailObj)
        .subscribe((result) => {

          this.dialogService.message("알림", "가맹문의 신청이 완료되었습니다.")
            .subscribe(() => {
              this.mailObj = {};
              this.phone = [];
              this.mobile = [];

              this.router.navigate(['before-login/intro']);
            });
        }, (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신 중 에러가 발생하였습니다.");
        })
    }
  }
}
