import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {UserService} from "../../services/user.service/user.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {AuthService} from "../../services/auth.service/auth.service";

@Component({
  selector: 'app-user-find',
  templateUrl: './user-find.component.html',
  styleUrls: ['./user-find.component.scss']
})
export class UserFind implements OnInit {

  regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  //for find Identifier
  identifierName: string;
  identifierEmail: string;
  foundIdentifier: string;
  toggleIdentifierArea: boolean;

  //for find Password

  passwordId: string;
  passwordEmail: string;
  certificateNumber: string;
  password: string;
  passwordConfirm: string;

  requestCertificate: boolean;
  togglePasswordArea: boolean;

  constructor(public appService: AppService,
              private router: Router,
              private userService: UserService,
              private dialogService: DialogService,
              private authService: AuthService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.initFindIdentifierForm();
    this.initFindPasswordForm();
  }

  initFindIdentifierForm() {
    this.identifierName = "";
    this.identifierEmail = "";
    this.foundIdentifier = "";
    this.toggleIdentifierArea = false;
  }

  initFindPasswordForm() {
    this.passwordId = "";
    this.passwordEmail = "";
    this.certificateNumber = "";
    this.password = "";
    this.passwordConfirm = "";

    this.requestCertificate = false;
    this.togglePasswordArea = false;

  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  validIdentifierInfo() {
    let valid = true, msg;

    if (!this.identifierName || this.identifierName === '') {
      valid = false;
      msg = "이름를 입력해주세요.";
    } else if (!this.identifierEmail || this.identifierEmail === '') {
      valid = false;
      msg = "이메일을 입력해주세요.";
    } else if (!this.regExp.test(this.identifierEmail)) {
      valid = false;
      msg = "이메일 형식이 맞지 않습니다. 이메일 형식으로 입력해주세요.";
    }

    if (valid == false) {
      this.dialogService.message("에러", msg);
    } else
      return true;
  }

  validPasswordInfo() {
    let valid = true, msg;

    if(!this.passwordId || this.passwordId === '') {
      valid = false;
      msg = "아이디를 입력해주세요.";
    } else if (!this.passwordEmail || this.passwordEmail === '') {
      valid = false;
      msg = "이메일을 입력해주세요.";
    } else if (!this.regExp.test(this.passwordEmail)) {
      valid = false;
      msg = "이메일 형식이 맞지 않습니다. 이메일 형식으로 입력해주세요.";
    }

    if (valid == false) {
      this.dialogService.message("에러", msg);
    } else
      return true;
  }

  isPasswordValid(){
    let valid = true, msg;

    if (!this.password) {
      valid = false;
      msg = "비밀번호를 입력해주세요.";
    } else if (!this.passwordConfirm) {
      valid = false;
      msg = "비밀번호를 확인해 주세요.";
    } else if (this.password !== this.passwordConfirm) {
      valid = false;
      msg = "입력하신 비밀번호가 일치하지 않습니다.";
    }

    if (valid == false) {
      this.dialogService.message("에러", msg);
    } else
      return true;
  }

  /*****************************
   *       helper functions
   *****************************/

  findIdentifier() {
    if (this.validIdentifierInfo()) {
      this.userService.findOne({
        query: {
          name: this.identifierName,
          email: this.identifierEmail
        }
      })
        .subscribe((result) => {
          this.toggleIdentifierArea = true;
          this.foundIdentifier = result.user.identifier;
        }, (error) => {
          console.log("error :::\n", error);
          this.toggleIdentifierArea = false;
          let msg = "";
          switch (error.status) {
            case 400:
              msg = "일치하는 아이디를 찾을 수 없습니다.";
              break;
            default:
              msg = "서버와의 통신 중 에러가 발생하였습니다.";
              return;
          }

          this.dialogService.message("에러", msg);
        });
    }
  }

  forgotPasswordStart() {
    if (this.validPasswordInfo()) {
      this.authService.forgotPasswordStart(this.passwordId, this.passwordEmail)
        .subscribe((result) => {
            this.dialogService.message("에러", '인증코드을 요청하였습니다. 1시간 이내에 메일로 발송 된 인증코드를 입력해 주시기 바랍니다.');
            this.requestCertificate = true;
          },
          (error) => {
            console.log("error :::\n", error);

            let msg = "";

            switch (error.status) {
              case 400:
                msg = '일치하는 회원정보가 없습니다. 회원가입시 입력했던 아이디와 이메일을 정확히 입력해 주시기 바랍니다.';
                break;
              case 402:
                msg = '하루 요청 횟수를 초과했습니다. 하루 요청 횟수는 최대 5번입니다.';
                break;
              case 500:
                msg = '서버에러';
                break;
              default:
                break;
            }

            this.dialogService.message("에러", msg);
          });
    }
  }

  forgotPasswordCheck(){
    this.authService.forgotPasswordCheck(this.passwordId, this.passwordEmail, this.certificateNumber)
      .subscribe((result) => {
          if(result.message === 'done') {
            this.requestCertificate = false;
            this.togglePasswordArea = true;
          }
        },
        (err) => {
          console.log("err :::\n", err);
          let subTitle = '';

          switch(err.status){
            case 400:
              subTitle = '입력한 정보를 확인해주세요.';
              break;
            case 412:
              subTitle = '인증코드를 요청해주세요.';
              break;
            case 422:
              subTitle = '인증코드 또는 요청시간이 만료되었습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }

          this.dialogService.message("에러", subTitle);
        });
  }

  forgotPasswordComplete(){
    let subTitle = "";

    if(this.isPasswordValid()){
      this.authService.forgotPasswordComplete(this.password)
        .subscribe(
          () => {
            subTitle = '새로운 비밀번호가 설정되었습니다.';
            this.dialogService.message("확인", subTitle)
              .subscribe(() => {
                this.initFindPasswordForm();
              });
          }, (err) => {
            console.log("err :::\n", err);
            switch(err.status){
              case 400:
                subTitle = '비밀번호는 6자 이상으로 작성해 주세요';
                break;
              case 412:
                subTitle = '비밀번호 재설정을 요청하지 않은 사용자입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = "잘못된 요청입니다.";
                break;
            }

            this.dialogService.message("에러", subTitle);
          });
    }
  }
}
