import {Injectable} from '@angular/core';
import {DialogMessage} from './dialog-message.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef: MatDialogRef<DialogMessage>;
  config: MatDialogConfig = new MatDialogConfig();

  constructor(public dialog: MatDialog) {
    // if (modalHeight < 820)
    this.config['height'] = 'auto';

    // if (modalWidth < 820)
    this.config['width'] = '600px';
    this.config.disableClose = true;
    this.config.panelClass = 'dialog-modal';
  }

  confirm(title, message) {
    this.dialogRef = this.dialog.open(DialogMessage, this.config);
    this.dialogRef.componentInstance.type = 'confirm';
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.message = message;

    return this.dialogRef.afterClosed();
  }

  message(title, message) {
    this.dialogRef = this.dialog.open(DialogMessage, this.config);
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.message = message;

    return this.dialogRef.afterClosed();
  }

}
