import * as _ from 'lodash';
import {Component, OnInit, ViewChild, ElementRef, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {BookService} from "../../services/book.service/book.service";

@Component({
  selector: 'app-dialog-grade-listening',
  templateUrl: './dialog-grade-listening.component.html',
  styleUrls: ['./dialog-grade-listening.component.scss']
})
export class DialogGradeListening implements OnInit {

  selectedDate;
  listeningData;

  constructor(public dialogRef: MatDialogRef<DialogGradeListening>,
              private bookService: BookService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {}

  /*****************************
   *        util functions
   *****************************/

  getListeningLabel(listening) {
    let bookCategory = '';
    let bookTitle = '';
    let fileName = '';

    if(listening.book && listening.listeningFile) {
      bookCategory = this.bookService.findCategory(listening.book.category).title;
      bookTitle = listening.book.title;
      fileName = listening.listeningFile ? listening.listeningFile.fileName : '';
      return bookCategory + '/' + bookTitle + '/' + fileName;
    } else {
      return '';
    }
  }

  close() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/
}
