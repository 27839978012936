import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {CampusService} from "../../services/campus.service/campus.service";
import {DialogService} from "../dialog-message/dialog-message.service";

@Component({
  selector: 'app-dialog-campus',
  templateUrl: './dialog-campus.component.html',
  styleUrls: ['./dialog-campus.component.scss']
})
export class DialogCampus implements OnInit {

  campuses: any;
  searchKeyword: any = '';

  constructor(public dialogRef: MatDialogRef<any>,
              private dialogService: DialogService,
              public campusService: CampusService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.loadList();
  }

  /*****************************
   *        util functions
   *****************************/

  save(input) {
    this.dialogRef.close(input);
  }

  close() {
    this.dialogRef.close();
  }

  enterPress(input) {
    if (input.keyCode == 13) {
      this.searchKeyword = input.target.value;
      this.loadList();
    }
  }

  /*****************************
   *       helper functions
   *****************************/

  loadList() {

    let params = {};

    if (this.searchKeyword && this.searchKeyword != "") {
      params['query'] = {
        campusName: {
          $regex: ".*" + this.searchKeyword + ".*"
        }
      }
    }

    this.campusService.find(params)
      .subscribe(data => {
        this.campuses = data.campuses;

      }, error => {
        console.log("error :::\n", error);
        this.dialogService.message("알림", "서버 응답이 업습니다.")
          .subscribe(() => {});
      });
  }
}
