import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {Intro} from './pages/intro/intro.component'
import {WelaBrand} from './pages/wela-brand/wela-brand.component';
import {BookInfo} from './pages/book-info/book-info.component';
import {PriodicalEducation} from './pages/priodical-education/priodical-education.component';
import {JoinMember} from './pages/join-member/join-member.component';
import {RegisterUser} from './pages/register-user/register-user.component';
import {ListeningReading} from './pages/listening-reading/listening-reading.component';
import {UserFind} from './pages/user-find/user-find.component';
import {AuthGuardService} from './services/auth-guard.service/auth-guard.service';
import {Post} from './pages/post/post.component';
import {IntroGreeting} from './pages/intro-greeting/intro-greeting.component';
import {PostDetail} from './pages/post/post-detail/post-detail.component';
import {CommunityWinner} from './pages/community-winner/community-winner.component';
import {PostManage} from './pages/post/post-manage/post-manage.component';
import {Grade} from './pages/grade/grade.component';
import {GradeDetail} from './pages/grade-detail/grade-detail.component';
import {StudentmanageDetail} from './pages/studentmanage-detail/studentmanage-detail.component';
import {StudentmanageList} from './pages/studentmanage-list/studentmanage-list.component';
import {ClassManage} from './pages/class-manage/class-manage.component';
import {CommunityPhoto} from './pages/community-photo/community-photo.component';
import {StudentGrade} from './pages/student-grade/student-grade.component';
import {MainUsagePolicy} from './pages/main-usage-policy/main-usage-policy.component';
import {MainPrivatePolicy} from './pages/main-private-policy/main-private-policy.component';

export const routes = [
  {
    path: '',
    redirectTo: '/before-login/intro',
    pathMatch: 'full',
  },

  //before-login
  {
    path: 'before-login',
    children: [
      {
        path: '',
        redirectTo: 'intro',
        pathMatch: 'full'
      },
      {
        label: '웰라 소개',
        path: 'intro',
        component: Intro
      },
      {
        label: 'BI',
        path: 'wela-brand',
        component: WelaBrand
      },
      {
        label: '교재 안내',
        path: 'book-info',
        component: BookInfo
      },
      {
        label: '정기 교육',
        path: 'priodical-education',
        component: PriodicalEducation
      },
      {
        label: '가맹 문의',
        path: 'join-member',
        component: JoinMember
      },
      {
        label: '회원 가입',
        path: 'register-user',
        component: RegisterUser
      },
      {
        label: '읽기 듣기 학습',
        path: 'listening-reading',
        component: ListeningReading
      },
      {
        label: '비밀번호 찾기',
        path: 'user-find',
        component: UserFind
      }
    ]
  },

  //student
  {
    path: 'student',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'campus-intro',
        pathMatch: 'full'
      },
      {
        label: '캠퍼스 소개',
        path: 'campus-intro',
        children: [
          {
            path: '',
            redirectTo: 'intro',
            pathMatch: 'full'
          },
          {
            label: '웰라 소개',
            path: 'intro',
            component: Intro
          },
          {
            label: '원장님 인사말',
            path: 'intro-greeting',
            component: IntroGreeting
          }
        ]
      },
      {
        label: '교재 안내',
        path: 'book-info',
        component: BookInfo
      },
      {
        label: '학습 센터',
        path: 'training-center',
        children: [
          {
            path: '',
            redirectTo: 'company-middleschoolenglish',
            pathMatch: 'full'
          },
          {
            headerText: '웰라 학습센터',
            label: 'Middle school English',
            path: 'company-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Level Test',
            path: 'company-leveltest',
            component: ListeningReading
          },
          {
            label: 'WeLA',
            path: 'company-wela',
            component: ListeningReading
          },
          {
            label: 'Best Friend',
            path: 'company-bestfriend',
            component: ListeningReading
          },
          {
            label: 'Story',
            path: 'company-story',
            component: ListeningReading
          },
          {
            label: 'Phonics',
            path: 'company-phonics',
            component: ListeningReading
          },
          {
            label: 'Vocabulary',
            path: 'company-vocabulary',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'company-reading',
            component: ListeningReading
          },
          {
            label: 'Writing',
            path: 'company-writing',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'company-listening',
            component: ListeningReading
          },
          {
            label: 'Newspaper',
            path: 'company-newspaper',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'company-etc',
            component: ListeningReading
          },

          {
            headerText: '캠퍼스 학습 센터',
            label: 'Middle school English',
            path: 'campus-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'campus-listening',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'campus-reading',
            component: ListeningReading
          },
          {
            label: 'Phonics, Voca',
            path: 'campus-PhonicsVoca',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'campus-etc',
            component: ListeningReading
          },
          {
            label: 'Special Recording',
            path: 'campus-specialrecording',
            component: ListeningReading
          }
        ]
      },

      {
        label: '커뮤니티',
        path: 'community',
        children: [
          {
            path: '',
            redirectTo: 'notice',
            pathMatch: 'full'
          },
          {
            label: '공지사항',
            path: 'notice',
            children: [
              {
                path: '',
                redirectTo: 'notice-list',
                pathMatch: 'full'
              },
              {
                label: '공지사항',
                path: 'notice-list',
                component: Post
              },
              {
                label: '공지사항 상세',
                path: 'notice-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          },
          {
            label: '숙제게시판',
            path: 'homework',
            children: [
              {
                path: '',
                redirectTo: 'homework-list',
                pathMatch: 'full'
              },
              {
                label: '숙제게시판',
                path: 'homework-list',
                component: Post
              },
              {
                label: '숙제게시판 상세',
                path: 'homework-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          },
          {
            label: '이달의 위너',
            path: 'winner',
            component: CommunityWinner,
          },
          {
            label: 'Essay 및 영작 게시판',
            path: 'essay',
            children: [
              {
                path: '',
                redirectTo: 'essay-list',
                pathMatch: 'full'
              },
              {
                label: 'Essay 및 영작 게시판',
                path: 'essay-list',
                component: Post
              },
              {
                label: 'Essay 및 영작 게시판 상세',
                path: 'essay-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: 'Essay 및 영작 게시판 작성 및 수정',
                path: 'essay-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '우리들의 이야기',
            path: 'ourstory',
            children: [
              {
                path: '',
                redirectTo: 'ourstory-list',
                pathMatch: 'full'
              },
              {
                label: '우리들의 이야기',
                path: 'ourstory-list',
                component: Post
              },
              {
                label: '우리들의 이야기 상세',
                path: 'ourstory-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '우리들의 이야기 작성 및 수정',
                path: 'ourstory-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 사진첩',
            path: 'gallery',
            children: [
              {
                path: '',
                redirectTo: 'gallery-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 사진첩',
                path: 'gallery-list',
                component: CommunityPhoto
              },
              {
                label: '캠퍼스 사진첩 상세',
                path: 'gallery-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          },
          {
            label: '동영상 갤러리',
            path: 'video',
            children: [
              {
                path: '',
                redirectTo: 'video-list',
                pathMatch: 'full'
              },
              {
                label: '동영상 갤러리',
                path: 'video-list',
                component: Post
              },
              {
                label: '동영상 갤러리 상세',
                path: 'video-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          }
        ]
      },
      {
        label: '자료실',
        path: 'data-center',
        children: [
          {
            path: '',
            redirectTo: 'weladatacenter',
            pathMatch: 'full'
          },
          {
            label: '웰라 자료실',
            path: 'weladatacenter',
            children: [
              {
                path: '',
                redirectTo: 'weladatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 자료실',
                path: 'weladatacenter-list',
                component: Post
              },
              {
                label: '웰라 자료실 상세',
                path: 'weladatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '웰라 자료실 작성 및 수정',
                path: 'weladatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 자료실',
            path: 'campusdatacenter',
            children: [
              {
                path: '',
                redirectTo: 'campusdatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 자료실',
                path: 'campusdatacenter-list',
                component: Post
              },
              {
                label: '캠퍼스 자료실 상세',
                path: 'campusdatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 자료실 작성 및 수정',
                path: 'campusdatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      }
    ]
  },

  //정회원 원장님, 정회원 선생님(regular-academy)
  {
    path: 'regular-academy',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'wela-english',
        pathMatch: 'full'
      },
      {
        label: '웰라 잉글리쉬',
        path: 'wela-english',
        children: [
          {
            path: '',
            redirectTo: 'intro',
            pathMatch: 'full'
          },
          {
            label: '웰라 소개',
            path: 'intro',
            component: Intro
          },
          {
            label: 'BI',
            path: 'wela-brand',
            component: WelaBrand
          },
        ]
      },
      {
        label: '캠퍼스 지원',
        path: 'campus-support',
        children: [
          {
            path: '',
            redirectTo: 'book-info',
            pathMatch: 'full'
          },
          {
            label: '교재 안내',
            path: 'book-info',
            component: BookInfo
          },
          {
            label: '정기 교육',
            path: 'priodical-education',
            component: PriodicalEducation
          },
        ]
      },
      {
        label: '선생님 그룹',
        path: 'teacher-group',
        children: [
          {
            path: '',
            redirectTo: 'welanews',
            pathMatch: 'full'
          },
          {
            label: '웰라 소식',
            path: 'welanews',
            children: [
              {
                path: '',
                redirectTo: 'welanews-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 소식',
                path: 'welanews-list',
                component: Post
              },
              {
                label: '웰라 소식 상세',
                path: 'welanews-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          },
          {
            label: '사랑방',
            path: 'sarangbang',
            children: [
              {
                path: '',
                redirectTo: 'sarangbang-list',
                pathMatch: 'full'
              },
              {
                label: '사랑방',
                path: 'sarangbang-list',
                component: Post
              },
              {
                label: '사랑방 상세',
                path: 'sarangbang-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '사랑방 작성 및 수정',
                path: 'sarangbang-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '초등 수업 자료실',
            path: 'elementrydatacenter',
            children: [
              {
                path: '',
                redirectTo: 'elementrydatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '초등 수업 자료실',
                path: 'elementrydatacenter-list',
                component: Post
              },
              {
                label: '초등 수업 자료실 상세',
                path: 'elementrydatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '초등 수업 자료실 작성 및 수정',
                path: 'elementrydatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '초등 게임 자료실',
            path: 'elementrygamecenter',
            children: [
              {
                path: '',
                redirectTo: 'elementrygamecenter-list',
                pathMatch: 'full'
              },
              {
                label: '초등 게임 자료실',
                path: 'elementrygamecenter-list',
                component: Post
              },
              {
                label: '초등 게임 자료실 상세',
                path: 'elementrygamecenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '초등 게임 자료실 작성 및 수정',
                path: 'elementrygamecenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '중등 자료실',
            path: 'middlecenter',
            children: [
              {
                path: '',
                redirectTo: 'middlecenter-list',
                pathMatch: 'full'
              },
              {
                label: '중등 자료실',
                path: 'middlecenter-list',
                component: Post
              },
              {
                label: '중등 자료실 상세 상세',
                path: 'middlecenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '중등 자료실 상세 작성 및 수정',
                path: 'middlecenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }

            ]
          }
        ]
      },
      {
        label: '내 캠퍼스',
        path: 'my-campus',
        children: [
          {
            path: '',
            redirectTo: 'grade-manage',
            pathMatch: 'full'
          },
          {
            label: '성적 관리',
            path: 'grade-manage',
            children: [
              {
                path: '',
                redirectTo: 'grade-list',
                pathMatch: 'full'
              },
              {
                label: '성적 관리',
                path: 'grade-list',
                component: Grade
              },
              {
                label: '성적 관리 상세',
                path: 'grade-detail/:studentId',
                component: GradeDetail
              }
            ]
          },
          {
            label: '학생 관리',
            path: 'student-manage',
            children: [
              {
                path: '',
                redirectTo: 'studentmanage-list',
                pathMatch: 'full'
              },
              {
                label: '학생 관리',
                path: 'studentmanage-list',
                component: StudentmanageList
              },
              {
                label: '학생 관리 상세',
                path: 'studentmanage-detail/:student',
                component: StudentmanageDetail
              }
            ]
          },
          {
            label: '클래스 관리',
            path: 'class-manage',
            component: ClassManage
          }
        ]
      },
      {
        label: '학습 센터',
        path: 'training-center',
        children: [
          {
            path: '',
            redirectTo: 'company-middleschoolenglish',
            pathMatch: 'full'
          },
          {
            headerText: '웰라 학습센터',
            label: 'Middle school English',
            path: 'company-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Level Test',
            path: 'company-leveltest',
            component: ListeningReading
          },
          {
            label: 'WeLA',
            path: 'company-wela',
            component: ListeningReading
          },
          {
            label: 'Best Friend',
            path: 'company-bestfriend',
            component: ListeningReading
          },
          {
            label: 'Story',
            path: 'company-story',
            component: ListeningReading
          },
          {
            label: 'Phonics',
            path: 'company-phonics',
            component: ListeningReading
          },
          {
            label: 'Vocabulary',
            path: 'company-vocabulary',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'company-reading',
            component: ListeningReading
          },
          {
            label: 'Writing',
            path: 'company-writing',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'company-listening',
            component: ListeningReading
          },
          {
            label: 'Newspaper',
            path: 'company-newspaper',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'company-etc',
            component: ListeningReading
          },

          {
            headerText: '캠퍼스 학습 센터',
            label: 'Middle school English',
            path: 'campus-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'campus-listening',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'campus-reading',
            component: ListeningReading
          },
          {
            label: 'Phonics, Voca',
            path: 'campus-PhonicsVoca',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'campus-etc',
            component: ListeningReading
          },
          {
            label: 'Special Recording',
            path: 'campus-specialrecording',
            component: ListeningReading
          }
        ]
      },

      {
        label: '커뮤니티',
        path: 'community',
        children: [
          {
            path: '',
            redirectTo: 'notice',
            pathMatch: 'full'
          },
          {
            label: '공지사항',
            path: 'notice',
            children: [
              {
                path: '',
                redirectTo: 'notice-list',
                pathMatch: 'full'
              },
              {
                label: '공지사항',
                path: 'notice-list',
                component: Post
              },
              {
                label: '공지사항 상세',
                path: 'notice-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '공지사항 작성 및 수정',
                path: 'notice-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '숙제게시판',
            path: 'homework',
            children: [
              {
                path: '',
                redirectTo: 'homework-list',
                pathMatch: 'full'
              },
              {
                label: '숙제게시판',
                path: 'homework-list',
                component: Post
              },
              {
                label: '숙제게시판 상세',
                path: 'homework-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '숙제게시판 작성 및 수정',
                path: 'homework-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '이달의 위너',
            path: 'winner',
            component: CommunityWinner,
          },
          {
            label: 'Essay 및 영작 게시판',
            path: 'essay',
            children: [
              {
                path: '',
                redirectTo: 'essay-list',
                pathMatch: 'full'
              },
              {
                label: 'Essay 및 영작 게시판',
                path: 'essay-list',
                component: Post
              },
              {
                label: 'Essay 및 영작 게시판 상세',
                path: 'essay-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: 'Essay 및 영작 게시판 작성 및 수정',
                path: 'essay-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '우리들의 이야기',
            path: 'ourstory',
            children: [
              {
                path: '',
                redirectTo: 'ourstory-list',
                pathMatch: 'full'
              },
              {
                label: '우리들의 이야기',
                path: 'ourstory-list',
                component: Post
              },
              {
                label: '우리들의 이야기 상세',
                path: 'ourstory-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '우리들의 이야기 작성 및 수정',
                path: 'ourstory-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 사진첩',
            path: 'gallery',
            children: [
              {
                path: '',
                redirectTo: 'gallery-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 사진첩',
                path: 'gallery-list',
                component: CommunityPhoto
              },
              {
                label: '캠퍼스 사진첩 상세',
                path: 'gallery-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 사진첩 작성 및 수정',
                path: 'gallery-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '동영상 갤러리',
            path: 'video',
            children: [
              {
                path: '',
                redirectTo: 'video-list',
                pathMatch: 'full'
              },
              {
                label: '동영상 갤러리',
                path: 'video-list',
                component: Post
              },
              {
                label: '동영상 갤러리 상세',
                path: 'video-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '동영상 갤러리 작성 및 수정',
                path: 'video-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      },
      {
        label: '자료실',
        path: 'data-center',
        children: [
          {
            path: '',
            redirectTo: 'weladatacenter',
            pathMatch: 'full'
          },
          {
            label: '웰라 자료실',
            path: 'weladatacenter',
            children: [
              {
                path: '',
                redirectTo: 'weladatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 자료실',
                path: 'weladatacenter-list',
                component: Post
              },
              {
                label: '웰라 자료실 상세',
                path: 'weladatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '웰라 자료실 작성 및 수정',
                path: 'weladatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 자료실',
            path: 'campusdatacenter',
            children: [
              {
                path: '',
                redirectTo: 'campusdatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 자료실',
                path: 'campusdatacenter-list',
                component: Post
              },
              {
                label: '캠퍼스 자료실 상세',
                path: 'campusdatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 자료실 작성 및 수정',
                path: 'campusdatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      }
    ]
  },

  //준회원 원장님, 준회원 선생님(associate-academy)
  {
    path: 'associate-academy',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'wela-english',
        pathMatch: 'full'
      },
      {
        label: '웰라 잉글리쉬',
        path: 'wela-english',
        children: [
          {
            path: '',
            redirectTo: 'intro',
            pathMatch: 'full'
          },
          {
            label: '웰라 소개',
            path: 'intro',
            component: Intro
          },
          {
            label: 'BI',
            path: 'wela-brand',
            component: WelaBrand
          },
        ]
      },
      {
        label: '캠퍼스 지원',
        path: 'campus-support',
        children: [
          {
            path: '',
            redirectTo: 'book-info',
            pathMatch: 'full'
          },
          {
            label: '교재 안내',
            path: 'book-info',
            component: BookInfo
          },
          {
            label: '정기 교육',
            path: 'priodical-education',
            component: PriodicalEducation
          },
        ]
      },
      {
        label: '내 캠퍼스',
        path: 'my-campus',
        children: [
          {
            path: '',
            redirectTo: 'grade-manage',
            pathMatch: 'full'
          },
          {
            label: '성적 관리',
            path: 'grade-manage',
            children: [
              {
                path: '',
                redirectTo: 'grade-list',
                pathMatch: 'full'
              },
              {
                label: '성적 관리',
                path: 'grade-list',
                component: Grade
              },
              {
                label: '성적 관리 상세',
                path: 'grade-detail/:studentId',
                component: GradeDetail
              }
            ]
          },
          {
            label: '학생 관리',
            path: 'student-manage',
            children: [
              {
                path: '',
                redirectTo: 'studentmanage-list',
                pathMatch: 'full'
              },
              {
                label: '학생 관리',
                path: 'studentmanage-list',
                component: StudentmanageList
              },
              {
                label: '학생 관리 상세',
                path: 'studentmanage-detail/:student',
                component: StudentmanageDetail
              }
            ]
          },
          {
            label: '클래스 관리',
            path: 'class-manage',
            component: ClassManage
          }
        ]
      },
      {
        label: '학습 센터',
        path: 'training-center',
        children: [
          {
            path: '',
            redirectTo: 'company-middleschoolenglish',
            pathMatch: 'full'
          },
          {
            headerText: '웰라 학습센터',
            label: 'Middle school English',
            path: 'company-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Level Test',
            path: 'company-leveltest',
            component: ListeningReading
          },
          {
            label: 'WeLA',
            path: 'company-wela',
            component: ListeningReading
          },
          {
            label: 'Best Friend',
            path: 'company-bestfriend',
            component: ListeningReading
          },
          {
            label: 'Story',
            path: 'company-story',
            component: ListeningReading
          },
          {
            label: 'Phonics',
            path: 'company-phonics',
            component: ListeningReading
          },
          {
            label: 'Vocabulary',
            path: 'company-vocabulary',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'company-reading',
            component: ListeningReading
          },
          {
            label: 'Writing',
            path: 'company-writing',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'company-listening',
            component: ListeningReading
          },
          {
            label: 'Newspaper',
            path: 'company-newspaper',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'company-etc',
            component: ListeningReading
          },

          {
            headerText: '캠퍼스 학습 센터',
            label: 'Middle school English',
            path: 'campus-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'campus-listening',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'campus-reading',
            component: ListeningReading
          },
          {
            label: 'Phonics, Voca',
            path: 'campus-PhonicsVoca',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'campus-etc',
            component: ListeningReading
          },
          {
            label: 'Special Recording',
            path: 'campus-specialrecording',
            component: ListeningReading
          }
        ]
      },
      {
        label: '커뮤니티',
        path: 'community',
        children: [
          {
            path: '',
            redirectTo: 'notice',
            pathMatch: 'full'
          },
          {
            label: '공지사항',
            path: 'notice',
            children: [
              {
                path: '',
                redirectTo: 'notice-list',
                pathMatch: 'full'
              },
              {
                label: '공지사항',
                path: 'notice-list',
                component: Post
              },
              {
                label: '공지사항 상세',
                path: 'notice-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '공지사항 작성 및 수정',
                path: 'notice-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '숙제게시판',
            path: 'homework',
            children: [
              {
                path: '',
                redirectTo: 'homework-list',
                pathMatch: 'full'
              },
              {
                label: '숙제게시판',
                path: 'homework-list',
                component: Post
              },
              {
                label: '숙제게시판 상세',
                path: 'homework-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '숙제게시판 작성 및 수정',
                path: 'homework-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '이달의 위너',
            path: 'winner',
            component: CommunityWinner,
          },
          {
            label: 'Essay 및 영작 게시판',
            path: 'essay',
            children: [
              {
                path: '',
                redirectTo: 'essay-list',
                pathMatch: 'full'
              },
              {
                label: 'Essay 및 영작 게시판',
                path: 'essay-list',
                component: Post
              },
              {
                label: 'Essay 및 영작 게시판 상세',
                path: 'essay-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: 'Essay 및 영작 게시판 작성 및 수정',
                path: 'essay-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '우리들의 이야기',
            path: 'ourstory',
            children: [
              {
                path: '',
                redirectTo: 'ourstory-list',
                pathMatch: 'full'
              },
              {
                label: '우리들의 이야기',
                path: 'ourstory-list',
                component: Post
              },
              {
                label: '우리들의 이야기 상세',
                path: 'ourstory-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '우리들의 이야기 작성 및 수정',
                path: 'ourstory-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 사진첩',
            path: 'gallery',
            children: [
              {
                path: '',
                redirectTo: 'gallery-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 사진첩',
                path: 'gallery-list',
                component: CommunityPhoto
              },
              {
                label: '캠퍼스 사진첩 상세',
                path: 'gallery-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 사진첩 작성 및 수정',
                path: 'gallery-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '동영상 갤러리',
            path: 'video',
            children: [
              {
                path: '',
                redirectTo: 'video-list',
                pathMatch: 'full'
              },
              {
                label: '동영상 갤러리',
                path: 'video-list',
                component: Post
              },
              {
                label: '동영상 갤러리 상세',
                path: 'video-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '동영상 갤러리 작성 및 수정',
                path: 'video-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      },
      {
        label: '자료실',
        path: 'data-center',
        children: [
          {
            path: '',
            redirectTo: 'weladatacenter',
            pathMatch: 'full'
          },
          {
            label: '웰라 자료실',
            path: 'weladatacenter',
            children: [
              {
                path: '',
                redirectTo: 'weladatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 자료실',
                path: 'weladatacenter-list',
                component: Post
              },
              {
                label: '웰라 자료실 상세',
                path: 'weladatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '웰라 자료실 작성 및 수정',
                path: 'weladatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 자료실',
            path: 'campusdatacenter',
            children: [
              {
                path: '',
                redirectTo: 'campusdatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 자료실',
                path: 'campusdatacenter-list',
                component: Post
              },
              {
                label: '캠퍼스 자료실 상세',
                path: 'campusdatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 자료실 작성 및 수정',
                path: 'campusdatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      }
    ]
  },

  //Admin
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'teacher-group',
        pathMatch: 'full'
      },
      {
        label: '선생님 그룹',
        path: 'teacher-group',
        children: [
          {
            path: '',
            redirectTo: 'welanews',
            pathMatch: 'full'
          },
          {
            label: '웰라 소식',
            path: 'welanews',
            children: [
              {
                path: '',
                redirectTo: 'welanews-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 소식',
                path: 'welanews-list',
                component: Post
              },
              {
                label: '웰라 소식 상세',
                path: 'welanews-detail/:category/:modelType/:post',
                component: PostDetail
              }
            ]
          },
          {
            label: '사랑방',
            path: 'sarangbang',
            children: [
              {
                path: '',
                redirectTo: 'sarangbang-list',
                pathMatch: 'full'
              },
              {
                label: '사랑방',
                path: 'sarangbang-list',
                component: Post
              },
              {
                label: '사랑방 상세',
                path: 'sarangbang-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '사랑방 작성 및 수정',
                path: 'sarangbang-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '초등 수업 자료실',
            path: 'elementrydatacenter',
            children: [
              {
                path: '',
                redirectTo: 'elementrydatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '초등 수업 자료실',
                path: 'elementrydatacenter-list',
                component: Post
              },
              {
                label: '초등 수업 자료실 상세',
                path: 'elementrydatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '초등 수업 자료실 작성 및 수정',
                path: 'elementrydatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '초등 게임 자료실',
            path: 'elementrygamecenter',
            children: [
              {
                path: '',
                redirectTo: 'elementrygamecenter-list',
                pathMatch: 'full'
              },
              {
                label: '초등 게임 자료실',
                path: 'elementrygamecenter-list',
                component: Post
              },
              {
                label: '초등 게임 자료실 상세',
                path: 'elementrygamecenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '초등 게임 자료실 작성 및 수정',
                path: 'elementrygamecenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '중등 자료실',
            path: 'middlecenter',
            children: [
              {
                path: '',
                redirectTo: 'middlecenter-list',
                pathMatch: 'full'
              },
              {
                label: '중등 자료실',
                path: 'middlecenter-list',
                component: Post
              },
              {
                label: '중등 자료실 상세 상세',
                path: 'middlecenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '중등 자료실 상세 작성 및 수정',
                path: 'middlecenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }

            ]
          }
        ]
      },
      {
        label: '내 캠퍼스',
        path: 'my-campus',
        children: [
          {
            path: '',
            redirectTo: 'grade-manage',
            pathMatch: 'full'
          },
          {
            label: '성적 관리',
            path: 'grade-manage',
            children: [
              {
                path: '',
                redirectTo: 'grade-list',
                pathMatch: 'full'
              },
              {
                label: '성적 관리',
                path: 'grade-list',
                component: Grade
              },
              {
                label: '성적 관리 상세',
                path: 'grade-detail/:studentId',
                component: GradeDetail
              }
            ]
          },
          {
            label: '학생 관리',
            path: 'student-manage',
            children: [
              {
                path: '',
                redirectTo: 'studentmanage-list',
                pathMatch: 'full'
              },
              {
                label: '학생 관리',
                path: 'studentmanage-list',
                component: StudentmanageList
              },
              {
                label: '학생 관리 상세',
                path: 'studentmanage-detail/:student',
                component: StudentmanageDetail
              }
            ]
          },
          {
            label: '클래스 관리',
            path: 'class-manage',
            component: ClassManage
          }
        ]
      },
      {
        label: '학습 센터',
        path: 'training-center',
        children: [
          {
            path: '',
            redirectTo: 'company-middleschoolenglish',
            pathMatch: 'full'
          },
          {
            headerText: '웰라 학습센터',
            label: 'Middle school English',
            path: 'company-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Level Test',
            path: 'company-leveltest',
            component: ListeningReading
          },
          {
            label: 'WeLA',
            path: 'company-wela',
            component: ListeningReading
          },
          {
            label: 'Best Friend',
            path: 'company-bestfriend',
            component: ListeningReading
          },
          {
            label: 'Story',
            path: 'company-story',
            component: ListeningReading
          },
          {
            label: 'Phonics',
            path: 'company-phonics',
            component: ListeningReading
          },
          {
            label: 'Vocabulary',
            path: 'company-vocabulary',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'company-reading',
            component: ListeningReading
          },
          {
            label: 'Writing',
            path: 'company-writing',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'company-listening',
            component: ListeningReading
          },
          {
            label: 'Newspaper',
            path: 'company-newspaper',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'company-etc',
            component: ListeningReading
          },

          {
            headerText: '캠퍼스 학습 센터',
            label: 'Middle school English',
            path: 'campus-middleschoolenglish',
            component: ListeningReading
          },
          {
            label: 'Listening',
            path: 'campus-listening',
            component: ListeningReading
          },
          {
            label: 'Reading',
            path: 'campus-reading',
            component: ListeningReading
          },
          {
            label: 'Phonics, Voca',
            path: 'campus-PhonicsVoca',
            component: ListeningReading
          },
          {
            label: 'Etc',
            path: 'campus-etc',
            component: ListeningReading
          },
          {
            label: 'Special Recording',
            path: 'campus-specialrecording',
            component: ListeningReading
          }
        ]
      },

      {
        label: '커뮤니티',
        path: 'community',
        children: [
          {
            path: '',
            redirectTo: 'notice',
            pathMatch: 'full'
          },
          {
            label: '공지사항',
            path: 'notice',
            children: [
              {
                path: '',
                redirectTo: 'notice-list',
                pathMatch: 'full'
              },
              {
                label: '공지사항',
                path: 'notice-list',
                component: Post
              },
              {
                label: '공지사항 상세',
                path: 'notice-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '공지사항 작성 및 수정',
                path: 'notice-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '숙제게시판',
            path: 'homework',
            children: [
              {
                path: '',
                redirectTo: 'homework-list',
                pathMatch: 'full'
              },
              {
                label: '숙제게시판',
                path: 'homework-list',
                component: Post
              },
              {
                label: '숙제게시판 상세',
                path: 'homework-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '숙제게시판 작성 및 수정',
                path: 'homework-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '이달의 위너',
            path: 'winner',
            component: CommunityWinner,
          },
          {
            label: 'Essay 및 영작 게시판',
            path: 'essay',
            children: [
              {
                path: '',
                redirectTo: 'essay-list',
                pathMatch: 'full'
              },
              {
                label: 'Essay 및 영작 게시판',
                path: 'essay-list',
                component: Post
              },
              {
                label: 'Essay 및 영작 게시판 상세',
                path: 'essay-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: 'Essay 및 영작 게시판 작성 및 수정',
                path: 'essay-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '우리들의 이야기',
            path: 'ourstory',
            children: [
              {
                path: '',
                redirectTo: 'ourstory-list',
                pathMatch: 'full'
              },
              {
                label: '우리들의 이야기',
                path: 'ourstory-list',
                component: Post
              },
              {
                label: '우리들의 이야기 상세',
                path: 'ourstory-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '우리들의 이야기 작성 및 수정',
                path: 'ourstory-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 사진첩',
            path: 'gallery',
            children: [
              {
                path: '',
                redirectTo: 'gallery-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 사진첩',
                path: 'gallery-list',
                component: CommunityPhoto
              },
              {
                label: '캠퍼스 사진첩 상세',
                path: 'gallery-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 사진첩 작성 및 수정',
                path: 'gallery-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '동영상 갤러리',
            path: 'video',
            children: [
              {
                path: '',
                redirectTo: 'video-list',
                pathMatch: 'full'
              },
              {
                label: '동영상 갤러리',
                path: 'video-list',
                component: Post
              },
              {
                label: '동영상 갤러리 상세',
                path: 'video-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '동영상 갤러리 작성 및 수정',
                path: 'video-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      },
      {
        label: '자료실',
        path: 'data-center',
        children: [
          {
            path: '',
            redirectTo: 'weladatacenter',
            pathMatch: 'full'
          },
          {
            label: '웰라 자료실',
            path: 'weladatacenter',
            children: [
              {
                path: '',
                redirectTo: 'weladatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '웰라 자료실',
                path: 'weladatacenter-list',
                component: Post
              },
              {
                label: '웰라 자료실 상세',
                path: 'weladatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '웰라 자료실 작성 및 수정',
                path: 'weladatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          },
          {
            label: '캠퍼스 자료실',
            path: 'campusdatacenter',
            children: [
              {
                path: '',
                redirectTo: 'campusdatacenter-list',
                pathMatch: 'full'
              },
              {
                label: '캠퍼스 자료실',
                path: 'campusdatacenter-list',
                component: Post
              },
              {
                label: '캠퍼스 자료실 상세',
                path: 'campusdatacenter-detail/:category/:modelType/:post',
                component: PostDetail
              },
              {
                label: '캠퍼스 자료실 작성 및 수정',
                path: 'campusdatacenter-manage/:category/:manageType/:modelType/:post',
                component: PostManage
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: '학생 성적 보기',
    path: 'student-grade',
    component: StudentGrade
  },
  {
    label: '이용약관',
    path: 'main-usage-policy',
    component: MainUsagePolicy
  },
  {
    label: '개인정보 처리방침',
    path: 'main-private-policy',
    component: MainPrivatePolicy
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
