import { Component } from '@angular/core';

@Component({
  selector: 'app-main-usage-policy',
  templateUrl: './main-usage-policy.component.html',
  styleUrls: ['./main-usage-policy.component.scss']
})
export class MainUsagePolicy {
  constructor() {}

  /*****************************
   *         life cycle
   *****************************/

  /*****************************
   *        util functions
   *****************************/

  /*****************************
   *       helper functions
   *****************************/
}
