import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  appEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  get user(): any {
    let user = localStorage.getItem('wela_user');

    try {
      user = JSON.parse(user);
      return user;
    } catch (e) {
      return null;
    }
  };

  set user(_user) {
    if (_user)
      localStorage.setItem('wela_user', JSON.stringify(_user));
    else
      localStorage.removeItem('wela_user');
  };

  get token(): any {
    let token = localStorage.getItem('wela_token');

    try {
      token = JSON.parse(token);
      return token;
    } catch (e) {
      return null;
    }
  };

  set token(_token: any) {
    if (_token)
      localStorage.setItem('wela_token', JSON.stringify(_token));
    else
      localStorage.removeItem('wela_token');
  };

  get myScore(): any {
    let myScore = localStorage.getItem('wela_myScore');

    try {
      myScore = JSON.parse(myScore);
      return myScore;
    } catch (e) {
      return null;
    }
  };

  set myScore(_myScore) {
    if (_myScore)
      localStorage.setItem('wela_myScore', JSON.stringify(_myScore));
    else
      localStorage.removeItem('wela_myScore');
  };

  get keepLogin(): any {
    let keepLogin = localStorage.getItem('wela_keepLogin');

    try {
      keepLogin = JSON.parse(keepLogin);
      return keepLogin;
    } catch (e) {
      return null;
    }
  };

  set keepLogin(_keepLogin) {
    localStorage.setItem('wela_keepLogin', JSON.stringify(_keepLogin));
  };

  sendEvent(eventName: string, data?: any) {
    let eventData: {name: string, data?: any} = {name: eventName};
    if (data) eventData.data = data;
    this.appEvent.emit(eventData);
  }
}
