import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostCodeService {

  constructor(private httpClient: HttpClient) { }

  searchPostCode(queryParams): Observable<any> {
    let url = `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${queryParams.confmKey}&currentPage=${queryParams.currentPage}&countPerPage=${queryParams.countPerPage}&keyword=${queryParams.searchKeyword}&resultType=json`;

    return this.httpClient.get(url);
  }
}
