import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogConfig, MatDialogRef, MatDialog} from '@angular/material';
// import {LocalStorage} from "ng2-localstorage";
import {ClassService} from '../../services/class.service/class.service';
import {DialogClassManage} from '../../components/dialog-class-manage/dialog-class-manage.component';
import {DialogService} from '../../components/dialog-message/dialog-message.service';
import {AppService} from '../../services/app.service/app.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-class-manage',
  templateUrl: './class-manage.component.html',
  styleUrls: ['./class-manage.component.scss']
})
export class ClassManage implements OnInit {
  selectedQuery: any;
  searchQueries: any;

  //for model
  classes: any;
  totalStudentCount: number = 0;
  currentStudentCount: number = 0;

  finishLoad: boolean = false;

  constructor(public classService: ClassService,
              private router: Router,
              public matDialog: MatDialog,
              private dialogService: DialogService,
              public appService: AppService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.selectedQuery = '제목+내용';
    this.searchQueries = ['제목+내용', '제목', '내용'];

    this.classes = [];
    this.getClassList();
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  modalOpener(input) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;
    dialogConfig.data = input;

    let dialogRef = this.matDialog.open(DialogClassManage, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      this.getClassList();
    });
  }

  /*****************************
   *       helper functions
   *****************************/

  getClassList() {
    this.currentStudentCount = 0;
    this.totalStudentCount = 0;
    this.classService
      .find({
        query: {
          campus: this.appService.user.campus._id
        },
        populate: [
          {
            path: 'students',
            match: {
              isDeleted: false,
              approvalStatus: true
            }
          },
        ],
        sort: {className: 1}
      })
      .pipe(
        delay(300)
      ).subscribe(data => {
        this.classes = data.classes;
        _.forEach(this.classes, (item) => {
          this.currentStudentCount += item.students.length;
          this.totalStudentCount += item.totalStudents;
        });

        this.finishLoad = true;
      },
      error => {
        console.log(error);

        this.finishLoad = true;
        this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.\n');
      }
    );
  }

  delClass(input) {
    this.dialogService.confirm('알림', '선택한 클래스를 삭제하시겠습니까?')
      .subscribe((result) => {
        if (result) {
          if (input.students.length === 0) {
            this.classService.remove(input._id)
              .subscribe((result) => {
                _.forEach(this.classes, (item) => {
                  this.currentStudentCount += item.countStudents;
                  this.totalStudentCount += item.totalStudents;
                });
                this.getClassList();
              }, error => {
                console.log('error :::\n', error);
                this.dialogService.message('에러', '서버와의 통신중 에러가 발생하였습니다.\n');
              });
          } else {
            this.dialogService.message('알림', '해당 클래스에 학생이 편성되어 있기 때문에 삭제 할 수 없습니다.');
          }
        }
      });
  }
}
