import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {MatDialogConfig, MatDialog} from "@angular/material";
import {AppService} from "../../services/app.service/app.service";
import {UserService} from "../../services/user.service/user.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {DialogGradeRecord} from '../../components/dialog-grade-record/dialog-grade-record.component'
import {DialogGradeListening} from '../../components/dialog-grade-listening/dialog-grade-listening.component'
import {DialogGradeAttend} from '../../components/dialog-grade-attend/dialog-grade-attend.component'
import {DialogGradeAdditional} from "../../components/dialog-grade-additional/dialog-grade-additional.component";
import {Point} from "../grade-detail/grade-detail.component";
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-student-grade',
  templateUrl: './student-grade.component.html',
  styleUrls: ['./student-grade.component.scss']
})
export class StudentGrade implements OnInit {
  //for Model
  studentId: any;
  student: any = {};

  currentMonth: any;
  today: any;

  daysByPointArray: Point[] = [];
  currentMonthCount = 0;
  attendanceCount = 0;
  attendancePercentage = 0;

  totalAttendanceScore = 0;
  totalListeningScore = 0;
  totalRecordingScore = 0;
  totalAdditionalScore = 0;
  totalScore = 0;

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              public userService: UserService,
              private dialogService: DialogService,
              private router: Router,
              public matDialog: MatDialog) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.studentId = this.appService.user._id;

    this.currentMonth = moment().month();
    this.today = moment();

    //for Calendar
    this.initCalendar();
  }

  initCalendar() {
    this.daysByPointArray = [];
    this.currentMonthCount = this.today.daysInMonth();
    this.attendanceCount = 0;
    this.attendancePercentage = 0;

    this.totalAttendanceScore = 0;
    this.totalListeningScore = 0;
    this.totalRecordingScore = 0;
    this.totalAdditionalScore = 0;
    this.totalScore = 0;

    for (let i = 0; i < this.currentMonthCount; i++) {
      let point = {
        date : moment(this.today).month(this.today.month()).startOf('month').add(i, 'day'),
        attendance: [],
        attendanceTotalScore: 0,
        listening: [],
        listeningTotalScore: 0,
        recording: [],
        recordingTotalScore: 0,
        additional: [],
        additionalTotalScore: 0,
        total: 0
      };
      this.daysByPointArray.push(point);
    }

    this.loadStudent();
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  openAttendanceDialog(selectedDate, data) {
    if (data.length == 0) return;

    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.height = '50%';
    dialogConfig.disableClose = true;

    let dialogRef = this.matDialog.open(DialogGradeAttend, dialogConfig);
    dialogRef.componentInstance.selectedDate = selectedDate;
    dialogRef.componentInstance.attendanceData = data;
  }

  openListeningDialog(selectedDate, data) {
    if (data.length == 0) return;

    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;

    let dialogRef = this.matDialog.open(DialogGradeListening, dialogConfig);
    dialogRef.componentInstance.selectedDate = selectedDate;
    dialogRef.componentInstance.listeningData = data;
  }

  openRecordingDialog(selectedDate, data) {
    if (data.length == 0) return;

    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.disableClose = true;
    dialogConfig.data = data;

    let dialogRef = this.matDialog.open(DialogGradeRecord, dialogConfig);
    dialogRef.componentInstance.selectedDate = selectedDate;
    dialogRef.componentInstance.recordingData = data;
  }

  openAdditionalDialog(selectedDate, data) {
    if (data.length == 0) return;

    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.height = '50%';
    dialogConfig.disableClose = true;
    dialogConfig.data = data;

    let dialogRef = this.matDialog.open(DialogGradeAdditional, dialogConfig);
    dialogRef.componentInstance.selectedDate = selectedDate;
    dialogRef.componentInstance.additionalData = data;
  }

  prevMonth(){
    this.today.subtract(1, 'month');
    this.initCalendar();
  }

  nextMonth(){
    this.today.add(1, 'month');
    this.initCalendar();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadStudent(): any {
    this.userService.findOne({
      query: {
        _id: this.studentId
      },
      populate: [
        'class',
        'campus',
        {
          path: "points",
          match: {
            createdAt: {
              $gte: this.today.startOf('month').toDate(),
              $lt: this.today.endOf('month').toDate()
            }
          },
          populate: [
            {path: "listeningFile", match: {isDeleted: false}},
            {path: "recordingFile", match: {isDeleted: false}},
            {path: "book", match: {isDeleted: false}}
          ]
        }
      ],
    }).pipe(
      delay(300)
    ).subscribe((result) => {
        this.student = result.user;

        //상단 점수계산
        _.forEach(this.student.points, (point) => {
          if (point.action === 'Attendance' && point.score === 5) {
            this.totalAttendanceScore += point.score;
            this.attendanceCount++;
          } else if (point.action === 'Listening') {
            this.totalListeningScore += point.score;
          } else if (point.action === 'Recording') {
            this.totalRecordingScore += point.score;
          } else if (point.action === 'Additional') {
            this.totalAdditionalScore += point.score;
          }
        });

        this.totalScore = this.totalAttendanceScore + this.totalListeningScore + this.totalRecordingScore + this.totalAdditionalScore;
        this.attendancePercentage = (this.attendanceCount / this.currentMonthCount) * 100;

        //성적표 점수 계산
        _.forEach(this.daysByPointArray, (dayByPoint) => {
          _.forEach(this.student.points, (point) => {
            if (moment(dayByPoint.date).isSame(point.createdAt, 'day')) {
              if (point.action === 'Attendance') {
                dayByPoint.attendance.push(point);
                dayByPoint.attendanceTotalScore += point.score;
              } else if (point.action === 'Listening') {
                dayByPoint.listening.push(point);
                dayByPoint.listeningTotalScore += point.score;
              } else if (point.action === 'Recording') {
                dayByPoint.recording.push(point);
                dayByPoint.recordingTotalScore += point.score;
              } else if (point.action === 'Additional') {
                dayByPoint.additional.push(point);
                dayByPoint.additionalTotalScore += point.score;
              }
              dayByPoint.total = dayByPoint.attendanceTotalScore + dayByPoint.listeningTotalScore + dayByPoint.recordingTotalScore + dayByPoint.additionalTotalScore;
            }
          })
        });
        this.finishLoad = true;
      }, error => {
        console.log("error::\n", error);
        this.finishLoad = true;
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
      });
  }
}
