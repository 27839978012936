import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-dialog-grade-attend',
  templateUrl: './dialog-grade-attend.component.html',
  styleUrls: ['./dialog-grade-attend.component.scss']
})
export class DialogGradeAttend implements OnInit {
  selectedDate;
  attendanceData;

  constructor(public dialogRef: MatDialogRef<DialogGradeAttend>) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
  }

  /*****************************
   *        util functions
   *****************************/

  close() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/
}
