import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessage implements OnInit {

  type;

  title;
  message;

  constructor(public dialogRef: MatDialogRef<DialogMessage>) { }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}

  /*****************************
   *        util functions
   *****************************/

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onKeypress(event) {
    if (event.keyCode == 13)
      this.confirm();
  }

}
