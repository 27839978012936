import * as _ from 'lodash';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, OnInit, OnDestroy, ViewChild, HostListener, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {Banner} from "../banner/banner.component";
import {AppService} from "../../services/app.service/app.service";
import {AuthService} from "../../services/auth.service/auth.service";
import {DialogService} from "../dialog-message/dialog-message.service";
import {routes} from "../../app-routing.module";
import {PointService} from "../../services/point.service/point.service";
import {BannerService} from "../../services/banner.service/banner.service";
import {CampusService} from "../../services/campus.service/campus.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class Sidenav implements OnInit {
  appEventDisposor: any;
  @ViewChild('navContent', {static: false}) navContent: ElementRef;

  user;

  identifier: string;
  password: string;

  checkLogin: boolean;  //로그인 여부

  currentSideMenuItems;
  sideMenuType;

  pointers: any = [];
  totalScore: number = 0;

  //for admin
  selectedCampus: any;
  campuses:any = [];

  constructor(private _router: Router,
              public appService: AppService,
              public dialogService: DialogService,
              private authService: AuthService,
              private pointService: PointService,
              private bannerService: BannerService,
              private campusService: CampusService,
              public matDialog: MatDialog) {
    _router.events.subscribe((val) => {
      this.scrollToTop();
    })
  }

  /*****************************
   *         life cycle
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'logout' :
        this.logOut();
        break;
      case 'login' :
        this.checkLogin = true;
        break;
      case 'updateUser':
        this.updateUser();
        break;
      case 'needScore' :
        this.getTotalPoint();
        break;
      case 'scrollTop' :
        this.scrollToTop();
      default:
        return;
    }
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  ngOnInit(): void {
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    if (this.appService.keepLogin !== null) {
      if(this.appService.keepLogin === true) {
        this.user = this.appService.user;
        this.totalScore = this.appService.myScore;
      }
    } else {
      this.appService.user = null;
      this.appService.token = null;
      this.user = null;
      this.appService.myScore = null;
    }

    this.initCurrentSideMenu();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    //TODO: 페이지를 닫을 때 '로그인 상태유지'가 체크되어 있지 않으면 localStorage의 정보들을 제거
    if(this.appService.keepLogin === false) {
      this.appService.user = null;
      this.appService.token = null;
      this.user = null;
      this.appService.myScore = null;
    }
  }

  initCurrentSideMenu() {
    if (this.appService.user && this.appService.token) {
      switch (this.appService.user.role) {
        case "학생" :
          this.sideMenuType = "student";
          break;
        case "관리자" :
          this.sideMenuType = "admin";
          break;
        case "원장님" :
          this.sideMenuType = "regular-academy";
          break;
        case "정회원 선생님" :
          this.sideMenuType = "regular-academy";
          break;
        case "준회원 원장님" :
          this.sideMenuType = "associate-academy";
          break;
        case "준회원 선생님" :
          this.sideMenuType = "associate-academy";
          break;
      }
    } else {
      this.sideMenuType = "";
    }

    switch (this.sideMenuType) {
      case "student":
        this.currentSideMenuItems = _.find(routes, {path: "student"});
        break;
      case "admin":
        this.currentSideMenuItems = _.find(routes, {path: "admin"});
        break;
      case "regular-academy":
        this.currentSideMenuItems = _.find(routes, {path: "regular-academy"});
        break;
      case "associate-academy":
        this.currentSideMenuItems = _.find(routes, {path: "associate-academy"});
        break;
      default:
        this.currentSideMenuItems = _.find(routes, {path: "before-login"});
        break;
    }
  }

  /*****************************
   *        util functions
   *****************************/

  isValid() {
    if (!this.identifier) return false;
    else if (this.identifier == '') return false;
    else if (!this.password) return false;
    else if (this.password == '') return false;

    return true;
  }

  menuToggle(item) {
    this.navContent.nativeElement.scrollTop = 0;
    let url = '/' + this.sideMenuType + '/' + item.path;
    if (!this.isActiveUrl(url)) {
      item.closed = false;
    } else {
      item.closed = !item.closed;
    }

    this._router.navigateByUrl(url);
  }

  gotoRoute(path, params?) {
    this.navContent.nativeElement.scrollTop = 0;
    if (!params) {
      this._router.navigateByUrl(path);
    } else {
      this._router.navigate([path], {queryParams: params})
    }
  }

  isActiveUrl(url) {
    return this._router.url.indexOf(url) >= 0;
  }

  updateUser() {
    this.user = this.appService.user;
  }

  getSubMenuHeight(item) {
    if (!item.children || !Array.isArray(item.children))
      return '0px';

    let childCount = item.children.length;

    _.forEach(item.children, (child) => {
      if (child.headerText) {
        childCount = childCount + 2;
      }
    });

    if (childCount != item.children.length)
      return (childCount * 45) - 20 + "px";

    return (childCount * 45) + 15 + "px";
  }

  campusChanged(event) {
    this.user.campus = event.value;
    localStorage.setItem('wela_user', JSON.stringify(this.user));
    this.gotoRoute('admin/teacher-group/welanews');
  }

  toggleKeepLogin() {
    if(this.appService.keepLogin !== null) {
      if(this.appService.keepLogin === false) this.appService.keepLogin = true;
      else this.appService.keepLogin = false;
    } else this.appService.keepLogin = true;
  }

  scrollToTop() {
    this.navContent.nativeElement.scrollTop = 0;
  }

  /*****************************
   *       helper functions
   *****************************/

  submit() {
    if (this.isValid()) {
      this.authService.login(this.identifier, this.password)
        .pipe(
          finalize(() => {
            if(this.appService.user && this.appService.user.role != '관리자')
              this.loadBanner();
          })
        )
        .subscribe((data) => {
          this.appService.user = data.user;
          this.appService.token = data.token;
          this.user = data.user;
          if (this.appService.user.role == "학생") {
            this.gotoRoute('student/campus-intro/intro');
            this.pointCreate();
          }
          else if (this.appService.user.role == "관리자") {
            this.fetchCampus();
            this.gotoRoute('admin/teacher-group/welanews');
          }
          else if (this.appService.user.role == "원장님") {
            this.gotoRoute('regular-academy/wela-english/intro');
          }
          else if (this.appService.user.role == "정회원 선생님") {
            this.gotoRoute('regular-academy/wela-english/intro');
          }
          else if (this.appService.user.role == "준회원 원장님") {
            this.gotoRoute('associate-academy/wela-english/intro');
          }
          else if (this.appService.user.role == "준회원 선생님") {
            this.gotoRoute('associate-academy/wela-english/intro');
          }
          this.initCurrentSideMenu();
        }, (err) => {
          console.log("err :::\n", err);
          switch (err.status) {
            case 401:
              this.dialogService.message("알림", "탈퇴한 회원이거나, 아이디 또는 패스워드가 일치하지 않습니다. 정확하게 입력해 주시기 바랍니다");
              break;
            case 402: {
              if(err.error.type == 'disapproval') {
                this.dialogService.message("알림", "현재 승인 대기중입니다. 승인 절차가 완료되면 웰라 홈페이지를 이용 하실 수 있습니다.");
                break;
              } else if(err.error.type == 'hasNotClass') {
                this.dialogService.message("알림", "아직 클래스에 배정되지 않았습니다. 캠퍼스 원장님께 클래스 배정 문의 바랍니다.");
                break;
              }
            }
            case 404:
              if(err.statusText == "Not Found") {
                this.dialogService.message("알림", "탈퇴한 회원이거나, 아이디 또는 패스워드가 일치하지 않습니다. 정확하게 입력해 주시기 바랍니다");
              } else {
                this.dialogService.message("알림", "서버와의 통신 중 에러가 발생하였습니다.");
              }
              break;
            default:
              this.dialogService.message("알림", "서버와의 통신 중 에러가 발생하였습니다.");
              break;
          }
        });
    } else {
      this.dialogService.message("알림", "아이디 또는 패스워드를 정확히 입력해주세요");
    }
  }

  loadBanner() {
    let params: any = {
      query: {
        campus: this.appService.user.campus._id,
        checkShow: true,
        isDeleted: false
      },
      limit: 1,
      sort: {createdAt: -1},
    };

    this.bannerService.find(params)
      .subscribe(
        (data) => {
          if (data.banners.length > 0) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.width = '360px';
            dialogConfig.height = '540px';
            dialogConfig.position = {top: '60px', left: '400px'};
            dialogConfig.disableClose = false;

            const dialogRef = this.matDialog.open(Banner, dialogConfig);
            dialogRef.componentInstance.banner = data.banners[0];
          }
        },
        (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("알림", "잘못된 요청입니다.")
            .subscribe(() => {});
        },
      );
  }

  fetchCampus() {
    this.campusService.find({})
      .subscribe((result) => {
        this.campuses = _.concat(this.campuses, result.campuses);
        this.selectedCampus = this.campuses[0];

        this.user.campus = this.selectedCampus;
        localStorage.setItem('wela_user', JSON.stringify(this.user));
      }, error => {
        console.log("error :::\n", error);
        this.dialogService.message("알림", "서버와의 통신 중 에러가 발생하였습니다.")
          .subscribe(() => {});
      });
  }

  logOut() {
    this.authService.logout()
      .subscribe((result) => {
        this.appService.user = null;
        this.appService.token = null;
        this.user = null;
        this.appService.myScore = null;
        this.gotoRoute('before-login/intro');
      }, (err) => {
        this.dialogService.message("알림", "에러가 발생하였습니다.");
      });
  }

  pointCreate() {
    let point = {
      student: this.appService.user._id,
      class: this.appService.user.class,
      action: 'Attendance'
    };
    this.pointService.createAttendancePoint(point)
      .pipe(
        finalize(() => {
          this.getTotalPoint();
        })
      )
      .subscribe((result) => {
        if (result.point && result.point.score === 5) {
          this.dialogService.message("알림", "오늘의 출석 포인트가 지급되었습니다.");
        }
      }, (error) => {
        console.log(error);
        this.dialogService.message("알림", "잘못된 요청입니다.")
          .subscribe(() => {
          });
      });
  }

  getTotalPoint() {
    this.totalScore = 0;

    this.pointService.find({
      query: {
        student: this.appService.user._id,
        createdAt: {
          $gte: moment().startOf('month').toDate(),
          $lt: moment().endOf('month').toDate()
        }
      }
    })
      .subscribe((result) => {
        this.pointers = result.pointers;

        _.forEach(this.pointers, (point) => {
          this.totalScore += point.score;
        });

        this.appService.myScore = this.totalScore;
      }, error => {
        this.dialogService.message("알림", "서버와의 통신중 에러가 발생하였습니다.\n")
      });
  }
}
