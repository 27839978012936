import {MatDialogRef} from "@angular/material";
import { Component, OnInit } from '@angular/core';
import {PostCodeService} from "../../services/post-code.service/post-code.service";
import {DialogService} from "../dialog-message/dialog-message.service";

@Component({
  selector: 'app-postcode-popup',
  templateUrl: './postcode-popup.component.html',
  styleUrls: ['./postcode-popup.component.scss']
})
export class PostcodePopup implements OnInit {
  public options = {
    rows: [],
    selected: null,
    headerHeight: "56px",
    rowHeight: "80px",
    bodyHeight: "100%",
    limit: 20,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  queryParams: any;

  constructor(public dialogRef: MatDialogRef<PostcodePopup>,
              public postCodeService: PostCodeService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.queryParams = {
      confmKey: `U01TX0FVVEgyMDE5MDIwNDA0MTA1NDEwODQ5MDg=`,
      currentPage: 1,
      countPerPage: 20,
      searchKeyword: ''
    }
  }

  /*****************************
   *        util functions
   *****************************/

  save(address) {
    this.dialogRef.close(address);
  }

  close() {
    this.dialogRef.close();
  }

  doEnter(input) {
    if (input.keyCode == 13) {
      this.searchAddress({page: 1});
    }
  }

  handleError(errorCode) {
    switch (errorCode) {
      case '-999':
        return '도로명주소 검색서버에서 에러가 발생하였습니다';
      case 'E0001':
        return '도로명주소 검색서버에서 에러가 발생하였습니다';
      case 'E0005':
        return '검색어를 입력해주세요.';
      case 'E0006':
        return '시도명으로는 검색이 불가합니다.';
      case 'E0008':
        return '한 글자만으로는 검색이 불가합니다.';
      case 'E0009':
        return '숫자만으로는 검색이 불가합니다.';
      case 'E0010':
        return '80글자를 초과한 검색어는 검색이 불가합니다.';
      case 'E0011':
        return '10자리를 초과하는 숫자가 포함된 검색어는 검색이 불가합니다.';
      case 'E0012':
        return '특수문자와 숫자만으로 이루어진 검색어는 검색이 불가합니다.';
    }
  }

  /*****************************
   *        helper functions
   *****************************/

  loadAddress() {
    return this.postCodeService.searchPostCode(this.queryParams);
  }

  searchAddress(event) {
    this.options.rows = [];
    this.options.page = event.page;

    this.loadAddress()
      .subscribe((data) => {
        if (data.results.common.errorCode != "0") {
          this.dialogService.message('알림', this.handleError(data.results.common.errorCode))
            .subscribe(() => {
            });
        } else {
          if (data.results.common.totalCount == 0) {
            this.dialogService.message('알림', `'${this.queryParams.searchKeyword}'에 대한 검색결과가 없습니다. 검색어를 변경해서 다시 검색해 보세요`)
              .subscribe(() => {
              });
          }
          this.options.rows = data.results.juso;
          this.options.count = data.results.common.totalCount;
        }
      }, error => {
        console.log("error::\n", error);

        this.dialogService.message("알림", "서버와의 통신 중 에러가 발생하였습니다.")
          .subscribe(() => {});
      })
  }
}
