import * as _ from 'lodash';
import * as moment from 'moment';
import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

import {AppService} from "../../services/app.service/app.service";
import {UserService} from "../../services/user.service/user.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {delay, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-community-winner',
  templateUrl: './community-winner.component.html',
  styleUrls: ['./community-winner.component.scss']
})
export class CommunityWinner implements OnInit {

  rankedUsers: any = [];
  classWinners: any = [];

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              private router: Router,
              private userService: UserService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.findTopTen();
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  /*****************************
   *       helper functions
   *****************************/

  findTopTen() {
    let params = {
      campus: this.appService.user.campus._id,
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate()
    };

    this.userService.findTopTen(params)
      .pipe(
        finalize(() => {
          this.findClassWinner();
        })
      )
      .subscribe((result) => {
        this.rankedUsers = result.rankedUsers;
      }, (error) => {
        this.finishLoad = true;
        console.log("error :::\n", error);
        this.dialogService.message("알림", "잘못된 요청입니다.")
      })
  }

  findClassWinner() {
    let params = {
      campus: this.appService.user.campus._id,
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate()
    };

    this.userService.findClassWinner(params)
      .pipe(
        delay(300)
      ).subscribe((result) => {
        _.remove(result.classWinners, winner => {
          return !winner
        });
        _.remove(result.classWinners, winner => {
          return !winner._id
        });

        this.classWinners = result.classWinners;
        this.finishLoad = true;
      }, (error) => {
        this.finishLoad = true;
        console.log("error :::\n", error);
        this.dialogService.message("알림", "잘못된 요청입니다.")
      })
  }
}
