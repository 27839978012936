import * as _ from 'lodash';
import * as moment from 'moment';
import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, RoutesRecognized} from "@angular/router";
import {AppService} from "../../services/app.service/app.service";
import {UserService} from "../../services/user.service/user.service";
import {PointService} from "../../services/point.service/point.service";
import {ClassService} from "../../services/class.service/class.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {RouteService} from '../../services/route.service/route.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss']
})
export class Grade implements OnInit {
  selectedQuery: any;
  searchQueries: any;
  queryString: string;

  monthStart: any;
  monthEnd: any;

  queryParams: any;
  queryWrapper: any;

  options: any;

  classes: any;
  currentClassId: number;
  studentsCount: number;

  finishLoad: boolean = false;

  constructor(public appService: AppService,
              public userService: UserService,
              public pointService: PointService,
              public classService: ClassService,
              private dialogService: DialogService,
              private router: Router,
              private routeService: RouteService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    this.selectedQuery = "이름";
    this.searchQueries = ['이름'];
    this.queryString = '';

    this.monthStart = moment().startOf('month');
    this.monthEnd = moment().endOf('month');

    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      approvalStatus: true,
      role: "학생"
    };

    this.options = {
      rows: [],
      selected: null,
      headerHeight: "56px",
      footerHeight: "80px",
      rowHeight: "80px",
      bodyHeight: "100%",
      limit: 10,
      count: 0,
      page: 1,
      sortAscending: 'icon-down',
      sortDescending: 'icon-up',
      pagerLeftArrow: 'prev1',
      pagerRightArrow: 'next1',
      pagerPrevious: 'prevEnd',
      pagerNext: 'nextEnd'
    };

    this.loadCount();
    this.loadClass();
    this.loadList({page: this.options.page});
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'], {queryParams: {'isEditMode': true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  gotoDetail(student) {
    if(this.appService.user.role == '관리자')
      this.router.navigate(['/admin/my-campus/grade-manage/grade-detail', student._id]);
    else if(this.appService.user.role == '원장님' || this.appService.user.role == '정회원 선생님')
      this.router.navigate(['/regular-academy/my-campus/grade-manage/grade-detail', student._id]);
    else if(this.appService.user.role == '준회원 원장님' || this.appService.user.role == '준회원 선생님')
      this.router.navigate(['/associate-academy/my-campus/grade-manage/grade-detail', student._id]);
  }

  changeClassCategory(classId?) {
    this.options.page = 1;

    this.queryString = '';
    this.queryWrapper = {
      campus: this.appService.user.campus._id,
      role: '학생'
    };

    if (classId) {
      this.currentClassId = classId;
      this.queryWrapper.class = classId;
    } else {
      this.currentClassId = null;
    }

    this.loadList({page: this.options.page});
  }

  onEvent(event) {
    event.stopPropagation();
  }

  checkUrl () {
    // TODO: -manage 갔다가 list 갈 경우 잘못된 쿼리를 날림
    const splitedString = _.split(this.routeService.getPreviousUrl(), '/', 4);
    const manageType = _.split(splitedString[3], '-', 1);

    return (manageType[0] === 'grade') && this.routeService.getPreviousUrl().includes('-detail')
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCount() {
    let params = {
      query: {
        campus: this.appService.user.campus._id,
        role: '학생'
      }
    }

    this.userService.count(params)
      .subscribe(
        (data) => {
          this.studentsCount = data.count;
        },
        (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다");
        });
  }

  loadClass() {
    let params = {
      query: {
        campus: this.appService.user.campus._id
      },
      populate: [
        {
          path: "students",
          match: {
            isDeleted: false
          },
        },
      ],
      sort: {createdAt: 1}
    };

    this.classService.find(params)
      .subscribe((data) => {
          this.classes = data.classes;
        }, (error) => {
          console.log("error :::\n", error);
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다")
        }
      )
  }

  loadList(event) {
    this.finishLoad = false;
    this.options.rows = [];
    this.appService.sendEvent('scrollTop');
    this.options.page = event.page;

    if(this.checkUrl()) {
      this.queryParams = this.userService.getGradeQueryParams();
      this.options.page = this.userService.getGradePage();
      this.currentClassId = this.userService.getGradeClassId();
      this.userService.initGradeParams();
      this.routeService.previousUrl = "";
    } else {
      this.queryParams = {
        query: this.queryWrapper,
        populate: [
          'class',
          {
            path: "points",
            match: {
              createdAt: {
                $gte: this.monthStart,
                $lt: this.monthEnd
              }
            }
          }
        ],
        limit: this.options.limit,
        skip: (this.options.page - 1) * this.options.limit,
        sort: {_id: -1}
      };
    }

    this.userService.setGradeQueryParams(this.queryParams);
    this.userService.setGradePage(this.options.page);
    this.userService.setGradeClassId(this.currentClassId);

    this.userService.find(this.queryParams)
      .pipe(
        delay(300)
      ).subscribe(
        (data) => {
          this.options.rows = data.users;
          this.options.count = data.total;
          _.forEach(this.options.rows, (student) => {
            student.lastAttendance = this.setLastAttendance(student);
            student.listening = this.checkListening(student, student.lastAttendance);
            student.recording = this.checkReading(student, student.lastAttendance);
            student.totalScore = this.getTotalScore(student);
          });

          this.finishLoad = true;
        },
        (error) => {
          console.log("error :::\n", error);
          this.finishLoad = true;
          this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다");
        });
  }

  setLastAttendance(student) {
    let attendanceArray = [];
    _.forEach(student.points, (point) => {
      if (point && point.action === 'Attendance')
        attendanceArray.push(point);
    });

    if (attendanceArray.length > 0) {
      let lastAttendance = _.maxBy(attendanceArray, (point) => {
        return point.createdAt;
      });

      return lastAttendance.createdAt;
    } else {
      return null;
    }
  }

  checkListening(student, lastAttendance) {
    let listeningArray = [];
    _.forEach(student.points, (point) => {
      if (point && point.action === 'Listening')
        listeningArray.push(point);
    });

    let i = 0;
    for (i; i < listeningArray.length; i++) {
      if (moment(lastAttendance).isSame(listeningArray[i].createdAt, 'day'))
        break;
    }

    if (i == listeningArray.length)
      return false;
    else
      return true;
  }

  checkReading(student, lastAttendance) {
    let recordingArray = [];
    _.forEach(student.points, (point) => {
      if (point && point.action === 'Recording')
        recordingArray.push(point);
    });

    let i = 0;
    for (i; i < recordingArray.length; i++) {
      if (moment(lastAttendance).isSame(recordingArray[i].createdAt, 'day'))
        break;
    }

    if (i == recordingArray.length)
      return false;
    else
      return true;
  }

  getTotalScore(student) {
    let totalScore = 0;
    _.forEach(student.points, (point) => {
      totalScore = totalScore + point.score;
    })

    return totalScore;
  }

  scoreSet(event, student, type) {
    event.stopPropagation();
    if(student.additionalScore && student.additionalScore != 0) {
      this.pointService
        .create({
          action: 'Additional',
          score: type == 'minus' ? (student.additionalScore * -1) : student.additionalScore,
          student: student._id,
          class: student.class
        })
        .subscribe((result) => {
            this.dialogService.message("알림", "추가점수가 지급되었습니다.")
              .subscribe((result) => {
                this.loadList({page: this.options.page});
                student.additionalScore = 0;
              });
          }, (error) => {
            console.log("error :::\n", error);
            this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다");
          }
        )
    } else {
      this.dialogService.message("알림", "점수를 입력해 주세요");
    }
  };

  searchByQuery() {
    if (this.queryString && this.queryString != '') {
      this.queryWrapper.name = {$regex: ".*" + this.queryString + ".*"};
      this.loadList({page: this.options.page});
    } else {
      this.dialogService.message("알림", "학생의 이름을 입력해 주세요");
    }
  }
}
