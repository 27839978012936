import {Component, OnInit, ElementRef, Input, SimpleChange, Renderer} from "@angular/core";

@Component({
  selector: 'app-content-compiler',
  template: '<ng-container></ng-container>'
})
export class ContentCompiler implements OnInit {
  @Input() html: string;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.elementRef.nativeElement.innerHTML = this.html;
    this.renderer.setElementClass(this.elementRef.nativeElement, 'ql-editor', true);
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['html']) {
      this.elementRef.nativeElement.innerHTML = this.html;
    }
  }
}
