import * as _ from 'lodash';
import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {CampusService} from "../../services/campus.service/campus.service";
import {DialogService} from "../../components/dialog-message/dialog-message.service";
import {AppService} from "../../services/app.service/app.service";
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-intro-greeting',
  templateUrl: './intro-greeting.component.html',
  styleUrls: ['./intro-greeting.component.scss']
})
export class IntroGreeting {
  campus: any;
  masterTeacher: any;

  finishLoad: boolean = false;

  constructor(public campusService: CampusService,
              private dialogService: DialogService,
              public appService: AppService,
              public router: Router) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(): void {
    if(this.appService.user)
      this.loadCampus();
  }

  /*****************************
   *        util functions
   *****************************/

  gotoUserFind() {
    this.router.navigate(['/before-login/user-find']);
  }

  gotoRegisterUser() {
    this.router.navigate(['/before-login/register-user']);
  }

  gotoUpdateMyinfo() {
    this.router.navigate(['/before-login/register-user'],{queryParams: {'isEditMode':true}});
  }

  logOut() {
    this.appService.sendEvent('logout');
  }

  gotoExternalLink(param) {
    switch(param) {
      case 'wela-times-academy' : {
        window.open("http://timesacademy.kr", "_blank");
        break;
      }
      case 'ne-times' : {
        window.open("http://www.netimes.co.kr", "_blank");
        break;
      }
      case 'campus-link' : {
        window.open(this.campus['linkUrl'], "_blank");
        break;
      }
    }
  }

  /*****************************
   *       helper functions
   *****************************/

  loadCampus() {
    this.campusService.findOne({
      query: {
        _id: this.appService.user.campus._id
      },
      populate: [{
        path: "users",
        match: {
          $or: [
            {role: '원장님'},
            {role: '준회원 원장님'}
          ]
        }}]
    }).pipe(
        delay(300)
      ).subscribe((result) => {
        this.campus = result.campus;
        this.masterTeacher = this.campus.users[0];
        this.finishLoad = true;
      }, error => {
        console.log("error :::\n", error);
        this.finishLoad = true;
        this.dialogService.message("에러", "서버와의 통신중 에러가 발생하였습니다.\n");
      });
  }
}
